import MomentUtils from '@date-io/moment';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment-timezone';
import React from 'react';

import translations from '../../../../translations/i18next';
import MDDateTimePickerView from './MDDateTimePickerView';
import { EDULAI_BLUE } from '../../../../styles/styleConsts';

const theme = createMuiTheme({
  palette: {
    primary: { 500: EDULAI_BLUE }
  },
  typography: {
    useNextVariants: true
  }
});

class MDDateTimePickerField extends React.Component {
  onHandleChange(value, input) {
    const { timezone } = this.props;
    const minutes = moment.tz(moment.utc(value).toISOString(), timezone).minutes();
    const roundedValues = moment.tz(moment.utc(value).toISOString(), timezone).set('minutes', minutes - (minutes % 5));
    const { onHandleChange } = this.props;

    if (input) {
      input.onChange(parseInt(roundedValues.valueOf() / 100000, 10) * 100000);
    }
    if (onHandleChange) {
      onHandleChange(moment(roundedValues, 'HH:mm A').valueOf());
    }
  }

  is12Hours = () => {
    const LTFormat = moment.localeData().longDateFormat('LT');
    if (LTFormat.split(' ').length > 1 && LTFormat.split(' ').pop() === 'A') {
      return true;
    }
    return false;
  };

  render() {
    const { input, meta, showErrorLabel, containerstyle, inline, format, defaultValue } = this.props;

    if (!input.value && defaultValue) {
      this.onHandleChange(defaultValue, input);
    }

    const hasError = meta.touched && meta.error;
    return (
      <MuiThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils} locale={moment.locale()} moment={moment}>
          <div style={{ backgroundColor: 'white', ...containerstyle }}>
            <MDDateTimePickerView
              error={hasError}
              onChange={(value) => this.onHandleChange(value, input)}
              value={input.value ? moment.tz(moment.utc(input.value), moment.tz.guess()) : null}
              ampm={this.is12Hours()}
              inputVariant="outlined"
              variant={inline ? 'inline' : 'dialog'}
              format={format || this.is12Hours() ? 'MMMM D h:mm a' : 'D MMMM H:mm'}
              minutesStep={5}
              invalidDateMessage={translations.t('forms.dateFormatInvalid')}
              {...this.props}
            />
            {showErrorLabel && hasError ? (
              <h6 style={{ color: 'red', marginTop: 4, marginLeft: 0 }}>{meta.error}</h6>
            ) : null}
          </div>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    );
  }
}

export default MDDateTimePickerField;
