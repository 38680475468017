/* eslint-disable dot-notation */
import { IconButton, Divider } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import palette from 'google-palette';
import _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { destroy } from 'redux-form';
import * as AnalyticsActions from '../../redux/actions/analytics.actions';
import * as PlatformUsersActions from '../../redux/actions/platformUsers.actions';
import * as SkillsActions from '../../redux/actions/skills.actions';
import * as UtilsActions from '../../redux/actions/utils.actions';
import translations from '../../translations/i18next';
import { enumerateDaysBetweenDates, generateRandomPalette, isMobileBrowser } from '../../utils/utilsFunctions';
import LineChart from '../Charts/LineChart';
import RadarChart from '../Charts/RadarChart';
import SelectableView from '../Forms/FormsComponents/SelectableInput/SelectableView';
import PrincipalChartFilterForm from '../Forms/PrincipalChartFilterForm/PrincipalChartFilterForm';
import MDAccordion from '../MDAccordion/MDAccordion';
import Spinner from '../Spinner/Spinner';
import AggregatedUserAnalytics from './AggregatedUserAnalytics';
import BestLeastPerformingUsers from './BestLeastPerformingUsers';
import ScrollableBarChart from '../Charts/ScrollableBarChart';
import BubbleChart from '../Charts/BubbleChart';
import { EDULAI_BLUE, EDULAI_PURPLE } from '../../styles/styleConsts';

const summaryUserPalette = generateRandomPalette(200);

class PrincipalAnalytics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      instructionsLink: '',
      isLoadingAggregatedStatystics: true,
      startDate: moment().subtract(1, 'week').valueOf(),
      endDate: moment().valueOf(),
      selectedUser: null,
      selectedSkillForAggregation: null,
      datasets: {
        radarChartSummaryDataset: [],
        bubbleChartSummaryDataset: []
      }
    };
  }

  async componentDidMount() {
    const { dispatch } = this.props;
    const instructionsLink = dispatch(UtilsActions.getInstructionLink());
    try {
      const skillsData = await dispatch(SkillsActions.fetchSkillsAndSubSkills());
      const firstSkills = _.first(_.orderBy(skillsData, 'id'));
      const selectedSkillOption = firstSkills ? { value: firstSkills.id, label: firstSkills.name } : null;
      dispatch(PlatformUsersActions.resetUsersFilters());
      await dispatch(PlatformUsersActions.fetchAllBaseUsers());
      await dispatch(AnalyticsActions.fetchSkillsAnalyticsSummary());
      await dispatch(AnalyticsActions.fetchSkillsAnalyticsUserSummary(firstSkills && firstSkills.id));
      await dispatch(AnalyticsActions.fetchBestUser());
      await dispatch(AnalyticsActions.fetchBadUser());
      // CREATE ALL CHART DATASETS FOR SUMMARY
      const radarChartSummaryDataset = dispatch(AnalyticsActions.createRadarSummaryDataset());
      const usersSummaryScoresDataSetsBubble = dispatch(AnalyticsActions.createSummaryBubbleChartDataset());
      const usersSummaryBarChartDataset = dispatch(AnalyticsActions.createSummaryBarChartDataset());
      // eslint-disable-next-line react/destructuring-assignment, react/no-access-state-in-setstate
      const data = this.state.datasets;
      data['radarChartSummaryDataset'] = radarChartSummaryDataset;
      data['bubbleChartSummaryDataset'] = usersSummaryScoresDataSetsBubble;
      data['barChartSummaryDataset'] = usersSummaryBarChartDataset;
      this.setState({
        datasets: data,
        isLoadingAggregatedStatystics: false,
        selectedSkillForAggregation: selectedSkillOption
      });
      await dispatch(
        AnalyticsActions.fetchSkillsAnalyticsByTime(moment().subtract(1, 'week').valueOf(), moment().valueOf())
      );
      this.setState({ instructionsLink, isLoading: false });
    } catch (error) {
      this.setState({ instructionsLink, isLoading: false });
    }
  }

  // eslint-disable-next-line react/sort-comp
  async reloadChart(values) {
    const {
      dispatch,
      platformUsers: {
        baseUsers: { content: platformUsersData }
      }
    } = this.props;
    this.setState({ selectedUser: null });
    dispatch(UtilsActions.setSpinnerVisible(true));
    const userId = values.participants.value !== 'all' ? values.participants.value : null;
    await dispatch(
      AnalyticsActions.fetchSkillsAnalyticsByTime(values.startDate, values.endDate, values.skills, userId)
    );
    const selectedUser = _.find(platformUsersData, (userData) => userData.id === userId);
    this.setState({ startDate: values.startDate, endDate: values.endDate, selectedUser });
    dispatch(UtilsActions.setSpinnerVisible(false));
  }

  async onResetFilters() {
    const { dispatch } = this.props;
    const startDate = moment().subtract(1, 'week').valueOf();
    const endDate = moment().valueOf();
    dispatch(UtilsActions.setSpinnerVisible(true));
    await dispatch(AnalyticsActions.fetchSkillsAnalyticsByTime(startDate, endDate));
    dispatch(UtilsActions.setSpinnerVisible(false));
    dispatch(destroy('PrincipalChartFilterForm'));
    this.setState({ startDate, endDate, selectedUser: null });
  }

  async onSelectSkillForAggregatedChart(skill) {
    const { dispatch } = this.props;
    try {
      const skillId = skill.value;
      await dispatch(AnalyticsActions.fetchSkillsAnalyticsUserSummary(skillId));
      this.setState({ selectedSkillForAggregation: skill });
    } catch (error) {
      dispatch(AnalyticsActions.fetchSkillsAnalyticsUserSummary());
      this.setState({ selectedSkillForAggregation: null });
    }
  }

  render() {
    const {
      analytics: {
        bestUser,
        badUser,
        skills: analyticsSkillsData,
        usersScores: { skillsScores: userSkillsScores }
      },
      platformUsers: {
        baseUsers: { content: platformUsersData }
      },
      skills: {
        data: { content: skillsData }
      }
    } = this.props;

    const {
      instructionsLink,
      datasets,
      isLoading,
      isLoadingAggregatedStatystics,
      startDate,
      endDate,
      selectedSkillForAggregation,
      selectedUser
    } = this.state;

    //  ------- SUMMARY DATA -------

    // Bad and Worst user
    const bestUserInfo = {
      ..._.find(platformUsersData, { id: bestUser.userId }),
      score: bestUser.score
    };
    const badUserInfo = {
      ..._.find(platformUsersData, { id: badUser.userId }),
      score: badUser.score
    };

    // Users scores per skill chart data
    // UNCOMMENT THIS PART IF YOU WANT TO HIDE USERS WITH 0 SCORE
    const filteredUsersSkillsScores = _.filter(userSkillsScores, (usersSkillsScore) => _.size(usersSkillsScore) > 1);
    const userScoresDataSets = {
      datasets: _.map(filteredUsersSkillsScores, (userSkillScore, index) => {
        const paletteIndex = (index + 1 + 200) % 200;
        return {
          label: `${translations.t('analytics.userScore')} ${userSkillScore.user.id}: ${userSkillScore.user.name} ${
            userSkillScore.user.surname
          }`,
          backgroundColor: summaryUserPalette ? `${summaryUserPalette[paletteIndex]}` : null,
          borderColor: 'rgba(72, 61, 139, 1)',
          data: _.map(_.omit(userSkillScore, 'user'), (skillScores) => skillScores.score)
        };
      }),
      labels: !selectedSkillForAggregation
        ? _.map(_.orderBy(skillsData, 'id'), (skill) => skill.name)
        : [selectedSkillForAggregation.label]
    };
    // Skills options for skill selection on chart 3
    const skillsOptions = [
      ..._.map(skillsData, (skill) => ({
        value: skill.id,
        label: skill.name
      }))
    ];

    const chartPalette = palette('mpn65', _.size(skillsData));

    // Section 2: Line chart data
    const dateLabels = enumerateDaysBetweenDates(startDate, endDate);

    const dataOverTime = _.map(analyticsSkillsData, (skill, id) => {
      const displayLabel = _.find(skillsData, { id: parseInt(id, 10) });
      return {
        label: displayLabel && displayLabel.name,
        values: _.map(dateLabels, (label) => {
          const date = _.find(skill, { createdAt: label });
          const finalPoint = date && date.score ? parseFloat(date.score).toFixed(1) : 0;
          return finalPoint;
        })
      };
    });

    const isMobile = isMobileBrowser();
    return (
      <div>
        {!isLoadingAggregatedStatystics && (
          <MDAccordion
            title={translations.t('analytics.overallAnalytics')}
            defaultExpanded
            containerstyle={{ marginTop: 10, marginBottom: 30, borderRadius: 5 }}
            panelStyle={{ paddinTop: 0 }}
            titleStyle={{ fontSize: 20, color: EDULAI_PURPLE }}
          >
            <div style={{ padding: 10 }}>
              <div style={{ marginBottom: 55, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <h4 style={{ marginTop: 0, marginBottom: 0, marginLeft: 12, fontSize: 18, fontWeight: '100' }}>
                  {translations.t('analytics.summaryDescription')}
                </h4>
                <IconButton style={{ marginLeft: 10 }} onClick={() => window.open(instructionsLink)}>
                  <InfoIcon style={{ color: EDULAI_BLUE }} />
                </IconButton>
              </div>
              <div style={{ width: '300', height: '300', backgroundColor: '#F9FAFB', padding: 50 }}>
                {datasets.radarChartSummaryDataset && (
                  <RadarChart data={datasets.radarChartSummaryDataset} min={0} max={5} stepSize={1} />
                )}
              </div>
              <BestLeastPerformingUsers bestUser={bestUserInfo} badUser={badUserInfo} />
              {datasets.bubbleChartSummaryDataset && (
                <div>
                  <h4 style={{ marginTop: 50, marginBottom: 5 }}>
                    {translations.t('analytics.userSummaryBubbleScores')}
                  </h4>
                  <h4 style={{ marginTop: 0, marginBottom: 40, fontSize: 18, fontWeight: '100' }}>
                    {translations.t('analytics.userSummaryBubbleScoresDescription')}
                  </h4>
                  <BubbleChart
                    height={isMobile ? 200 : 100}
                    data={_.cloneDeep(datasets.bubbleChartSummaryDataset)}
                    min={0}
                    max={100}
                    yAxeTitle={translations.t('analytics.usersPercentage')}
                    xAxeTitle={translations.t('analytics.skillsScores')}
                    chartPalette={summaryUserPalette}
                    legend
                  />
                </div>
              )}
              {datasets.barChartSummaryDataset && (
                <div>
                  <h4 style={{ marginTop: 20, marginBottom: 5 }}>{translations.t('analytics.userSummaryScores')}</h4>
                  <h4 style={{ marginTop: 0, marginBottom: 40, fontSize: 18, fontWeight: '100' }}>
                    {translations.t('analytics.userSummaryScoresDescription')}
                  </h4>
                  <ScrollableBarChart
                    height={isMobile ? 200 : 100}
                    data={datasets.barChartSummaryDataset}
                    dataSetSize={_.size(filteredUsersSkillsScores)}
                    min={0}
                    max={5}
                    yAxeTitle={translations.t('analytics.skillsScores')}
                    chartPalette={summaryUserPalette}
                    legend
                  />
                </div>
              )}
              <Divider />
              <div>
                {selectedSkillForAggregation ? (
                  <h4>{translations.t('analytics.usersSkillScores', { skill: selectedSkillForAggregation.label })}</h4>
                ) : (
                  <h4>{translations.t('analytics.usersSkillsScores')}</h4>
                )}
                <h4 style={{ margin: 0, fontWeight: '100', fontSize: 18 }}>
                  {translations.t('analytics.selectSkillForChart')}
                </h4>
                <div style={{ margin: 15, marginBottom: 20 }}>
                  <SelectableView
                    isClearable
                    input={{ value: selectedSkillForAggregation }}
                    placeholder={translations.t('forms.selectSkill')}
                    defaultOptions={skillsOptions}
                    onHandleChange={(skill) => this.onSelectSkillForAggregatedChart(skill)}
                  />
                </div>
                <ScrollableBarChart
                  height={isMobile ? 200 : 100}
                  data={userScoresDataSets}
                  dataSetSize={_.size(filteredUsersSkillsScores)}
                  min={0}
                  max={5}
                  yAxeTitle={translations.t('analytics.skillsScores')}
                  chartPalette={summaryUserPalette}
                  legend
                />
              </div>
            </div>
          </MDAccordion>
        )}
        {isLoading && (
          <div style={{ height: '110vh' }}>
            <Spinner
              title={translations.t('general.loading')}
              hideLogo
              spinnerStyle={{ color: EDULAI_BLUE, marginTop: 10 }}
              titleStyle={{ color: '#3f3f3f', marginTop: 5 }}
            />
          </div>
        )}
        {!isLoading && (
          <MDAccordion
            title={translations.t('analytics.detailedAnalytics')}
            defaultExpanded
            containerstyle={{ marginTop: 10, marginBottom: 30, borderRadius: 5 }}
            panelStyle={{ paddinTop: 0 }}
            titleStyle={{ fontSize: 20, color: EDULAI_PURPLE }}
          >
            <div style={{ padding: 10, paddingTop: 0 }}>
              <PrincipalChartFilterForm
                onSubmit={(values) => this.reloadChart(values)}
                onResetFilters={() => this.onResetFilters()}
              />
              <div style={{ padding: 10, paddingTop: 0 }}>
                {selectedUser ? (
                  <h4>
                    {translations.t('analytics.chartForUserOverTime', {
                      user: `${selectedUser.name} ${selectedUser.surname}`
                    })}
                  </h4>
                ) : (
                  <h4>{translations.t('analytics.overallAnalyticsOverTime')}</h4>
                )}
                <h4 style={{ fontWeight: '100', fontSize: 18 }}>
                  {translations.t('analytics.overallAnalyticsOverTimeDescription')}
                </h4>
              </div>
              <LineChart
                height={isMobile ? 200 : 100}
                labels={dateLabels}
                data={dataOverTime}
                yAxeTitle={translations.t('analytics.skillsScores')}
                stepSize={1}
                min={0}
                max={5}
                chartPalette={chartPalette}
                legend
              />
              {selectedUser ? (
                <div>
                  <h4>
                    {translations.t('analytics.overallStatisticsForUser', {
                      user: `${selectedUser.name} ${selectedUser.surname}`
                    })}
                  </h4>
                  <AggregatedUserAnalytics user={selectedUser} />
                </div>
              ) : (
                <div>
                  <h4>{translations.t('analytics.chartForUser')}</h4>
                  <div style={{ marginTop: 30, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <InfoIcon style={{ color: EDULAI_BLUE }} />
                    <h4 style={{ fontWeight: '100', margin: 0, marginLeft: 10 }}>
                      {translations.t('analytics.overallStatisticsForUserDescription')}
                    </h4>
                  </div>
                </div>
              )}
            </div>
          </MDAccordion>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  analytics: state.analytics,
  platformUsers: state.platformUsers,
  skills: state.skills,
  language: state.utils.selectedLanguage
});

export default connect(mapStateToProps)(PrincipalAnalytics);
