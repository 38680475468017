import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import Chip from '@material-ui/core/Chip';
import { createMuiTheme, MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import SecurityIcon from '@material-ui/icons/Security';
import React from 'react';
import { withRouter } from 'react-router-dom';

import { EDULAI_BLUE, EDULAI_PURPLE } from '../../../styles/styleConsts';
import translations from '../../../translations/i18next';
import UserCardIcon from '../../Icons/UserCardIcon';

const styles = () => ({
  outerContainer: {
    position: 'relative',
  },
  eventContainer: {
    padding: 15,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  typography: {
    useNextVariants: true,
  },
  title: {
    margin: 0,
    textAlign: 'left',
    fontWeight: 800,
    fontSize: 18,
    color: '#3f3f3f',
  },
  dateText: {
    margin: 0,
    color: '#979da1',
    fontSize: 15,
    marginTop: 5,
  },
  disabledText: {
    margin: 0,
    marginTop: 5,
    fontSize: 12,
    color: 'red',
    position: 'absolute',
    right: 10,
    top: 10,
  },
  body: {
    margin: 0,
    marginTop: 10,
    color: '#3f3f3f',
    textAlign: 'left',
    fontWeight: 'normal',
    fontSize: 18,
  },
  userTag: {
    marginRight: 10,
    paddingLeft: 10,
    paddingRight: 10,
    marginTop: 15,
  },
  titleContainer: {
    marginBottom: 10,
  },
  tag: {
    display: 'flex',
    margin: 'auto',
  },
});

const theme = createMuiTheme({
  palette: {
    primary: { 500: EDULAI_PURPLE },
    seconday: EDULAI_PURPLE,
  },
  typography: {
    useNextVariants: true,
  },
});

const PlatformUserRow = ({
  user: {
    role: { name: roleName },
    name,
    surname,
    email,
    enabled,
    userActivationUrl,
    userTagOutDTOS: tags,
  },
  onClick,
  classes,
  containerstyle,
}) => {
  return (
    <MuiThemeProvider theme={theme}>
      <Card
        style={{
          ...containerstyle,
          opacity: enabled ? 1 : 0.6,
          boxShadow: 'none',
          border: '2px solid #D1D5DB',
          borderRadius: 8,
        }}
        className={classes.outerContainer}
      >
        <CardActionArea className={classes.eventContainer} onClick={() => onClick()}>
          <div>
            <div className={classes.titleContainer}>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
                <UserCardIcon />
                <div style={{ marginLeft: 20 }}>
                  <h3 className={classes.title}>{`${name} ${surname}`}</h3>
                  <h3 className={classes.body} style={{ margin: 0 }}>
                    {`${email}`}
                  </h3>
                </div>
              </div>

              {!enabled && !userActivationUrl && (
                <h4 className={classes.disabledText}>{translations.t('users.disabled').toUpperCase()}</h4>
              )}
              {!enabled && userActivationUrl && (
                <h4 className={classes.disabledText}>{translations.t('users.inactive').toUpperCase()}</h4>
              )}
            </div>
            <Chip
              color="primary"
              label={translations.t(`roles.${roleName}`)}
              icon={<SecurityIcon />}
              style={{ marginTop: 10 }}
            />
            {tags && tags[0] && (
              <div style={{ position: 'absolute', right: 0, bottom: 0, margin: 15 }}>
                <div className={classes.tag}>
                  <Chip
                    style={{
                      backgroundColor: `${EDULAI_BLUE}`,
                      color: 'white',
                      marginLeft: 10,
                      paddingLeft: 10,
                      paddingRight: 10,
                    }}
                    label={tags[0].name}
                  />
                </div>
              </div>
            )}
          </div>
        </CardActionArea>
      </Card>
    </MuiThemeProvider>
  );
};

export default withStyles(styles)(withRouter(PlatformUserRow));
