//
// ──────────────────────────────────────────────────────────────────────────────────────────── I ──────────
//   :::::: O P E R A T I O N A L   V I E W   C O M P O N E N T : :  :   :    :     :        :          :
// ──────────────────────────────────────────────────────────────────────────────────────────────────────
//

import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { isMobileBrowser } from '../../utils/utilsFunctions';
import { COMPONENT_BACKGROUND, EDULAI_BACKGROUND_GRADIENT_COLOR } from '../../styles/styleConsts';

/*
 * The operational view it's a drawer component that will popup from right
 * and render an inner content
 */
class MDOperationalView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  componentDidMount() {
    setTimeout(() => this.setState({ isOpen: true }), 100);
  }

  onClose() {
    const { onCancel, hideModal } = this.props;

    if (onCancel) {
      onCancel();
    } else {
      this.setState({ isOpen: false }, () => setTimeout(() => hideModal(), 500));
    }
  }

  onConfirm() {
    const { onConfirm, hideModal } = this.props;
    if (onConfirm) {
      onConfirm();
    } else {
      hideModal();
    }
  }

  render() {
    const { content, title, ancor, containerstyle } = this.props;
    const { isOpen } = this.state;
    const isMobile = isMobileBrowser();
    return (
      <Drawer
        style={{ zoom: isMobile ? '60%' : 'unset', zIndex: 1251 }}
        anchor={ancor || 'right'}
        open={isOpen}
        onClose={() => this.onClose()}
      >
        <div
          style={{
            paddingTop: isMobile ? 20 : 0,
            paddingLeft: 15,
            paddingRight: 15,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            background: EDULAI_BACKGROUND_GRADIENT_COLOR,
            height: 80,
          }}
        >
          <h2 style={{ color: 'white' }}>{title || ''}</h2>
          <IconButton onClick={() => this.onClose()}>
            <CloseIcon style={{ color: 'white' }} />
          </IconButton>
        </div>
        <div
          style={{
            width: isMobile ? '100%' : '100vh',
            height: '100%',
            backgroundColor: COMPONENT_BACKGROUND,
            ...containerstyle,
          }}
        >
          {content}
        </div>
      </Drawer>
    );
  }
}

export default MDOperationalView;
