import { CssBaseline, List, ListItem } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import { createMuiTheme, MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import HeaderButton from '../../components/Header/HeaderButton';
import tranlations from '../../translations/i18next';
import { isMobileBrowser } from '../../utils/utilsFunctions';
import AppContainer from './AppContainer';
import Permission from '../../components/Permission/Permission';
import { PERMISSIONS, PERMISSION_ENTITIES, USER_ROLES } from '../../config/configurations';
import AbilityProvider from '../../utils/PermissionsUtils/AbilityProvider';
import SkillCheckerLogo from '../../components/Logo/SkillCheckerLogo';
import { COMPONENT_BACKGROUND, EDULAI_BLUE, EDULAI_ORANGE } from '../../styles/styleConsts';
import EdulaiLogo from '../../components/Logo/EdulaiLogo';
import sidebarBackground from '../../assets/sidebar_bg.png';
import QuizIcon from '../../components/Icons/QuizIcon';
import UserIcon from '../../components/Icons/UserIcon';
import QuestionIcon from '../../components/Icons/QuestionIcon';
import SkillIcon from '../../components/Icons/SkillIcon';
import StatisticsIcon from '../../components/Icons/StatisticsIcon';
import DomainIcon from '../../components/Icons/DomainIcon';
import SettingsIcon from '../../components/Icons/SettingsIcon';
import skillckeckerLogo from '../../assets/logo2.png';

const drawerWidth = 260;

const styles = (theme) => ({
  root: {
    position: 'fixed',
    left: 0,
    right: 0,
    zIndex: 1000,
    backgroundColor: '#f8f9fc'
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      flexShrink: 0,
      width: drawerWidth
    }
  },
  menuButton: {
    marginRight: 20,
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    border: 'none'
  }
});

const theme = createMuiTheme({
  palette: {
    primary: { 500: EDULAI_BLUE }
  },
  typography: {
    useNextVariants: true
  }
});

const SideBar = ({ languange }) => (
  <div
    style={{
      zoom: '100%',
      height: '100vh',
      backgroundImage: `url(${sidebarBackground})`,
      display: 'flex',
      flexDirection: 'column',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover'
    }}
  >
    <div
      style={{
        width: '100%',
        height: 60,
        backgroundColor: 'white',
        display: 'flex',
        alignItems: 'center',
        paddingbottom: 10
      }}
    >
      <EdulaiLogo height={30} />
    </div>
    <div
      style={{
        display: 'flex',
        width: '100%',
        backgroundColor: 'transparent',
        paddingTop: 30
      }}
    >
      <SkillCheckerLogo height={55} />
    </div>
    <List style={{ marginTop: 20 }}>
      <Permission
        do={[PERMISSIONS.READ]}
        on={PERMISSION_ENTITIES.QUIZ}
        rolesNot={[USER_ROLES.PRINCIPAL]}
        abilityHelper={AbilityProvider.getDomainAbilityHelper()}
      >
        <ListItem button key={tranlations.t('header.quiz')} style={{ height: 55, margin: 0, padding: 0 }}>
          <HeaderButton
            title={tranlations.t('header.quiz')}
            titleStyleSelected={{ color: EDULAI_ORANGE, fontWeight: 'bold' }}
            icon={<QuizIcon color="rgba(255,255,255,.8)" />}
            iconSelected={<QuizIcon color={EDULAI_ORANGE} />}
            paths={['/quizzes']}
          />
        </ListItem>
      </Permission>
      <Permission
        do={[PERMISSIONS.UPDATE]}
        on={PERMISSION_ENTITIES.USER}
        abilityHelper={AbilityProvider.getDomainAbilityHelper()}
      >
        <ListItem button key={tranlations.t('header.users')} style={{ height: 55, margin: 0, padding: 0 }}>
          <HeaderButton
            title={tranlations.t('header.users')}
            titleStyleSelected={{
              color: EDULAI_ORANGE,
              fontWeight: 'bold'
            }}
            icon={<UserIcon color="rgba(255,255,255,.8)" />}
            iconSelected={<UserIcon color={EDULAI_ORANGE} />}
            paths={['/users']}
          />
        </ListItem>
      </Permission>
      <Permission
        do={[PERMISSIONS.UPDATE]}
        on={PERMISSION_ENTITIES.QUESTION}
        abilityHelper={AbilityProvider.getDomainAbilityHelper()}
      >
        <ListItem button key={tranlations.t('header.questions')} style={{ height: 55, margin: 0, padding: 0 }}>
          <HeaderButton
            title={tranlations.t('header.questions')}
            titleStyleSelected={{ color: EDULAI_ORANGE, fontWeight: 'bold' }}
            icon={<QuestionIcon color="rgba(255,255,255,.8)" />}
            iconSelected={<QuestionIcon color={EDULAI_ORANGE} />}
            paths={['/questions']}
          />
        </ListItem>
      </Permission>
      <Permission
        do={[PERMISSIONS.UPDATE]}
        on={PERMISSION_ENTITIES.SKILL}
        abilityHelper={AbilityProvider.getDomainAbilityHelper()}
      >
        <ListItem button key={tranlations.t('header.skills')} style={{ height: 55, margin: 0, padding: 0 }}>
          <HeaderButton
            title={tranlations.t('header.skills')}
            titleStyleSelected={{ color: EDULAI_ORANGE, fontWeight: 'bold' }}
            icon={<SkillIcon color="rgba(255,255,255,.8)" />}
            iconSelected={<SkillIcon color={EDULAI_ORANGE} />}
            paths={['/skills']}
          />
        </ListItem>
      </Permission>
      <Permission
        do={[PERMISSIONS.READ]}
        on={PERMISSION_ENTITIES.ANALYTICS}
        rolesNot={[USER_ROLES.SUPERVISOR]}
        abilityHelper={AbilityProvider.getDomainAbilityHelper()}
      >
        <ListItem button key={tranlations.t('header.statistics')} style={{ height: 55, margin: 0, padding: 0 }}>
          <HeaderButton
            title={tranlations.t('header.statistics')}
            titleStyleSelected={{ color: EDULAI_ORANGE, fontWeight: 'bold' }}
            icon={<StatisticsIcon color="rgba(255,255,255,.8)" />}
            iconSelected={<StatisticsIcon color={EDULAI_ORANGE} />}
            paths={['/statistics']}
          />
        </ListItem>
      </Permission>
      <Permission
        do={[PERMISSIONS.READ]}
        on={PERMISSION_ENTITIES.DOMAIN}
        rolesNot={[USER_ROLES.USER]}
        abilityHelper={AbilityProvider.getDomainAbilityHelper()}
      >
        <ListItem button key={tranlations.t('header.domain')} style={{ height: 55, margin: 0, padding: 0 }}>
          <HeaderButton
            title={tranlations.t('header.domain')}
            titleStyleSelected={{
              fontFamily: 'Oxygen',
              color: EDULAI_ORANGE,
              fontWeight: 'bold'
            }}
            icon={<DomainIcon color="rgba(255,255,255,.8)" />}
            iconSelected={<DomainIcon color={EDULAI_ORANGE} />}
            paths={['/domain']}
          />
        </ListItem>
      </Permission>
      <ListItem button key={tranlations.t('header.settings')} style={{ height: 55, margin: 0, padding: 0 }}>
        <HeaderButton
          title={tranlations.t('header.settings')}
          titleStyleSelected={{
            fontFamily: 'Oxygen',
            color: EDULAI_ORANGE,
            fontWeight: 'bold'
          }}
          containerSelectedStyle={{ backgroundColor: 'red' }}
          icon={<SettingsIcon color="rgba(255,255,255,.8)" />}
          iconSelected={<SettingsIcon color={EDULAI_ORANGE} />}
          paths={['/settings']}
        />
      </ListItem>
    </List>
  </div>
);

class SidebarLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showHeader: false
    };
  }

  toggleHeaderMenu() {
    const { showHeader } = this.state;
    this.setState({ showHeader: !showHeader });
  }

  profileRouting() {
    const {
      user: { data: userData },
      history
    } = this.props;
    if (userData.isPhotographer) {
      history.push('/myProfile');
    } else {
      history.push('/profile');
    }
  }

  render() {
    const { classes, children, languange } = this.props;
    const { showHeader } = this.state;

    return (
      <MuiThemeProvider theme={theme}>
        <div className={classes.root}>
          <CssBaseline />
          {isMobileBrowser() && (
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={() => this.toggleHeaderMenu()}
                className={classes.menuButton}
              >
                <div style={{ marginTop: 3 }}>
                  <MenuIcon style={{ marginBottom: 5 }} />
                  <img src={skillckeckerLogo} alt="edulai" className="logo" style={{ height: 30, marginLeft: 20 }} />
                </div>
              </IconButton>
            </Toolbar>
          )}
          <div className={classes.drawer}>
            <Hidden smUp implementation="css">
              <Drawer
                variant="temporary"
                anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                open={showHeader}
                onClose={() => this.toggleHeaderMenu()}
                classes={{
                  paper: classes.drawerPaper
                }}
                ModalProps={{
                  keepMounted: true // Better open performance on mobile.
                }}
              >
                <SideBar languange={languange} />
              </Drawer>
            </Hidden>
            <Hidden xsDown implementation="css">
              <Drawer
                classes={{
                  paper: classes.drawerPaper
                }}
                variant="permanent"
                open
              >
                <SideBar languange={languange} />
              </Drawer>
            </Hidden>
          </div>
        </div>
        <div
          style={{
            paddingTop: isMobileBrowser() ? 60 : 0,
            paddingLeft: isMobileBrowser() ? 0 : 260,
            height: '100vh',
            backgroundColor: COMPONENT_BACKGROUND
          }}
        >
          <AppContainer>{children}</AppContainer>
        </div>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  utils: state.utils,
  user: state.user,
  language: state.utils.selectedLanguage
});

export default connect(mapStateToProps)(withRouter(withStyles(styles)(SidebarLayout)));
