import { createMuiTheme, MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import UsersIcon from '@material-ui/icons/SupervisedUserCircle';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { initialize } from 'redux-form';
import UserTagForm from '../../components/Forms/UsersForm/UserTagForm';
import ListComponent from '../../components/ListComponents/ListComponent';
import UserTagRow from '../../components/ListComponents/RowComponents/UserTagRow';
import Spinner from '../../components/Spinner/Spinner';
import * as ModalsActions from '../../redux/actions/modal.actions';
import * as PlatformUserActions from '../../redux/actions/platformUsers.actions';
import * as UtilsActions from '../../redux/actions/utils.actions';
import { EDULAI_BLUE, EDULAI_BLUE_DARK } from '../../styles/styleConsts';
import translations from '../../translations/i18next';

const styles = (theme) => ({
  title: {
    margin: 10,
    color: '#5a5c69',
    fontWeight: '200'
  },
  chartsContainer: {
    marginTop: 10
  },
  noUserContainer: {
    padding: 20,
    display: 'flex',
    alignItems: 'center'
  },
  noUserText: {
    margin: 0,
    color: '#7F888F'
  },
  noUserIcon: {
    fontSize: 40,
    color: '#7F888F',
    marginRight: 20
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  listContainer: {
    paddingTop: 15,
    marginTop: 7
  }
});

const theme = createMuiTheme({
  palette: {
    primary: { 500: EDULAI_BLUE_DARK }
  },
  typography: {
    useNextVariants: true
  }
});

class UsersView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false
    };
  }

  async componentDidMount() {
    const { dispatch } = this.props;
    try {
      this.setState({ isLoading: true });
      await dispatch(PlatformUserActions.fetchTagsWithUsers());
      this.setState({ isLoading: false });
    } catch (error) {
      this.setState({ isLoading: false });
    }
  }

  async onAppendTags(page) {
    const { dispatch } = this.props;
    await dispatch(PlatformUserActions.fetchTagsWithUsers(page));
  }

  async onNewTagClicked() {
    const { dispatch } = this.props;
    dispatch(
      ModalsActions.showModal('CREATE_NEW_TAG_MODAL', {
        modalType: 'OPERATIONAL_VIEW',
        modalProps: {
          content: (
            <div style={{ padding: 20 }}>
              <UserTagForm onSubmit={(tagData) => this.onCreateNewTag(tagData)} />
            </div>
          ),
          title: translations.t('forms.createNewTagDescription')
        }
      })
    );
  }

  async onCreateNewTag(tagData) {
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisible(true));
      await dispatch(PlatformUserActions.createUserTag(tagData));
      dispatch(UtilsActions.setSpinnerVisible(false));
      dispatch(ModalsActions.hideModal('CREATE_NEW_TAG_MODAL'));
      dispatch(
        ModalsActions.showModal('CREATE_USER_TAG_MODAL_SUCCESS', {
          modalType: 'SUCCESS_ALERT',
          modalProps: {
            message: translations.t('users.createUserTagSuccess')
          }
        })
      );
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisible(false));
      dispatch(
        ModalsActions.showModal('CREATE_USER_TAG_MODAL_ERROR', {
          modalType: 'ERROR_ALERT',
          modalProps: {
            message: translations.t('users.createUserTagError')
          }
        })
      );
    }
  }

  async onEditTagClicked(tag) {
    const { dispatch } = this.props;
    dispatch(initialize('UserTagForm', { name: tag.name, id: tag.id }));
    dispatch(
      ModalsActions.showModal('EDIT_TAG_MODAL', {
        modalType: 'OPERATIONAL_VIEW',
        modalProps: {
          content: (
            <div style={{ padding: 20 }}>
              {/*  {tag.id !== 1 && <h3>{translations.t('forms.modifyTagDescription')}</h3>} */}
              <UserTagForm
                tag={tag}
                onDeleteTag={(tagData) => this.onDeleteTagRequest(tagData)}
                onSubmit={(tagData) => this.onEditTag(tagData)}
              />
            </div>
          ),
          title: translations.t('forms.modifyTagDescription')
        }
      })
    );
  }

  async onEditTag(tagData) {
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisible(true));
      await dispatch(PlatformUserActions.editUserTag(tagData));
      await dispatch(PlatformUserActions.fetchTagsWithUsers());
      dispatch(UtilsActions.setSpinnerVisible(false));
      dispatch(ModalsActions.hideModal('EDIT_TAG_MODAL'));
      dispatch(
        ModalsActions.showModal('EDIT_USER_TAG_MODAL_SUCCESS', {
          modalType: 'SUCCESS_ALERT',
          modalProps: {
            message: translations.t('users.editUserTagSuccess')
          }
        })
      );
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisible(false));
      dispatch(
        ModalsActions.showModal('EDIT_USER_TAG_MODAL_ERROR', {
          modalType: 'ERROR_ALERT',
          modalProps: {
            message: translations.t('users.editUserTagError')
          }
        })
      );
    }
  }

  onDeleteTagRequest(tagData) {
    const { dispatch } = this.props;
    dispatch(
      ModalsActions.showModal('DELETE_TAG_MODAL', {
        modalType: 'MODAL_DIALOG',
        modalProps: {
          title: translations.t('forms.warning'),
          bodyText: translations.t('forms.deleteTagConfirm'),
          onConfirm: () => this.onDeleteTagConfirm(tagData),
          confirmText: translations.t('modals.confirm')
        }
      })
    );
  }

  async onDeleteTagConfirm(tag) {
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisible(true));
      dispatch(ModalsActions.hideModal('DELETE_TAG_MODAL'));
      await dispatch(PlatformUserActions.deleteTag(tag.id));
      await dispatch(PlatformUserActions.fetchTagsWithUsers());
      dispatch(UtilsActions.setSpinnerVisible(false));
      dispatch(ModalsActions.hideModal('EDIT_TAG_MODAL'));
      dispatch(
        ModalsActions.showModal('DELETE_USER_TAG_MODAL_SUCCESS', {
          modalType: 'SUCCESS_ALERT',
          modalProps: {
            message: translations.t('users.deleteTagSuccess')
          }
        })
      );
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisible(false));
      dispatch(
        ModalsActions.showModal('DELETE_USER_TAG_MODAL_ERROR', {
          modalType: 'ERROR_ALERT',
          modalProps: {
            message: translations.t('users.deleteTagError')
          }
        })
      );
    }
  }

  render() {
    const {
      classes,
      platformUsers: {
        tags: { content: tagsData, pagination }
      }
    } = this.props;
    const { isLoading } = this.state;
    return (
      <div>
        <MuiThemeProvider theme={theme}>
          <div className={classes.container}>
            <ListComponent
              pagination={pagination}
              containerstyle={{ width: '100%', marginTop: 20 }}
              newElementText={translations.t('forms.createNewTag')}
              onLoadMore={(page) => this.onAppendTags(page)}
              onCreateNew={() => this.onNewTagClicked()}
              actionCardStyle={{ padding: 20 }}
            >
              <div className={classes.listContainer} style={{ overflowY: 'scroll', maxHeight: '75vh' }}>
                {_.map(tagsData, (tag) => (
                  <UserTagRow
                    key={tag.id}
                    tag={tag}
                    outerContainerstyle={{ marginTop: 15 }}
                    onClick={() => this.onEditTagClicked(tag)}
                    containerstyle={{ boxShadow: 'none', border: '2px solid #D1D5DB', borderRadius: 8 }}
                  />
                ))}
                {(!tagsData || _.isEmpty(tagsData)) && !isLoading && (
                  <div className={classes.noUserContainer}>
                    <UsersIcon className={classes.noUserIcon} />
                    <h4 className={classes.noUserText}>{translations.t('users.noUsersTagFound')}</h4>
                  </div>
                )}
                {isLoading && _.isEmpty(tagsData) && (
                  <Spinner
                    title={translations.t('general.loading')}
                    hideLogo
                    spinnerStyle={{ color: EDULAI_BLUE, marginTop: 10 }}
                    titleStyle={{ color: '#3f3f3f', marginTop: 5 }}
                  />
                )}
              </div>
            </ListComponent>
          </div>
        </MuiThemeProvider>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  language: state.utils.selectedLanguage,
  roles: state.roles,
  platformUsers: state.platformUsers,
  personalData: state.user.data
});

export default connect(mapStateToProps)(withStyles(styles)(UsersView));
