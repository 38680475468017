/* eslint-disable react/jsx-one-expression-per-line */
import Card from '@material-ui/core/Card';
import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { submit, initialize } from 'redux-form';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import { Chip, Divider } from '@material-ui/core';
import * as DomainActions from '../../redux/actions/domain.actions';
import * as ModalsActions from '../../redux/actions/modal.actions';
import * as UtilsActions from '../../redux/actions/utils.actions';
import { EDULAI_BLUE, EDULAI_PURPLE, ORANGE, RED } from '../../styles/styleConsts';
import translations from '../../translations/i18next';
import DomainCreditsForm from '../Forms/DomainForms/DomainCreditsForm';
import MDButton from '../MDButton/MDButton';
import DomainLicenseForm from '../Forms/DomainForms/DomainLicenseForm';
import Permission from '../Permission/Permission';
import { PERMISSIONS, PERMISSION_ENTITIES } from '../../config/configurations';
import AbilityProvider from '../../utils/PermissionsUtils/AbilityProvider';
import CustomFieldForm from '../Forms/DomainForms/CustomFieldForm';
import CustomFieldRow from '../ListComponents/RowComponents/CustomFieldRow';

const styles = (theme) => ({
  cardContainer: {
    padding: 20,
  },
  title: {
    margin: 0,
    color: EDULAI_PURPLE,
  },
  subtitle: {
    color: '#3f3f3f',
    fontWeight: '100',
    margin: 0,
    fontSize: 18,
    marginTop: 5,
  },
  warningContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 10,
  },
  domainEnableContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  domainTypeContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 20,
  },
  creditsContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
});

class DomainInfoView extends Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(DomainActions.fetchDomainData());
  }

  onShowCreditsModal() {
    const { dispatch, domainData } = this.props;
    dispatch(
      ModalsActions.showModal('ADD_CREDITS_MODAL', {
        modalType: 'MODAL_DIALOG',
        modalProps: {
          title: translations.t('forms.credits'),
          hideCancel: true,
          content: (
            <DomainCreditsForm
              startCredits={domainData.credits}
              onSubmit={(creditsData) => this.onModifyCredits(creditsData)}
            />
          ),
          bodyTextStyle: { fontSize: 18 },
          bodyText: translations.t('forms.addCreditsDescription'),
          onConfirm: () => dispatch(submit('DomainCreditsForm')),
          confirmText: translations.t('modals.confirm'),
        },
      }),
    );
  }

  async onModifyCredits(creditsData) {
    const { dispatch } = this.props;
    try {
      dispatch(ModalsActions.hideModal('ADD_CREDITS_MODAL'));
      dispatch(UtilsActions.setSpinnerVisible(true));
      if (creditsData.operationType === 'SET') {
        await dispatch(DomainActions.setDomainCredits({ credits: creditsData.credits }));
      } else {
        await dispatch(DomainActions.addDomainCredits({ credits: creditsData.credits }));
      }
      dispatch(
        ModalsActions.showModal('CREDIT_SUCCESS_MODAL', {
          modalType: 'SUCCESS_ALERT',
          modalProps: {
            message: translations.t('forms.addCreditSuccess'),
          },
        }),
      );
      dispatch(UtilsActions.setSpinnerVisible(false));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisible(false));
      dispatch(
        ModalsActions.showModal('ADD_CREDIT_ERROR_MODAL', {
          modalType: 'ERROR_ALERT',
          modalProps: {
            message: translations.t('forms.addCreditError'),
          },
        }),
      );
    }
  }

  onOpenLicenseTypeModal() {
    const { dispatch } = this.props;
    dispatch(
      ModalsActions.showModal('MODIFY_LICENSE_MODAL', {
        modalType: 'MODAL_DIALOG',
        modalProps: {
          title: translations.t('forms.subscriptionType'),
          hideCancel: true,
          content: <DomainLicenseForm onSubmit={(licenseData) => this.onModifyLicenseTypeRequest(licenseData)} />,
          bodyTextStyle: { fontSize: 18 },
          bodyText: translations.t('forms.modifyDomainTypeDescription'),
          onConfirm: () => dispatch(submit('DomainLicenseForm')),
          confirmText: translations.t('modals.confirm'),
        },
      }),
    );
  }

  onModifyLicenseTypeRequest(licenseData) {
    const { dispatch } = this.props;
    dispatch(
      ModalsActions.showModal('MODIFY_LICENSE_REQUEST_MODAL', {
        modalType: 'MODAL_DIALOG',
        modalProps: {
          title: translations.t('forms.warning'),
          bodyTextStyle: { fontSize: 18 },
          bodyText: translations.t('forms.modifyDomainTypeWarning'),
          onConfirm: () => this.onModifyLicenseTypeConfirm(licenseData),
        },
      }),
    );
  }

  async onModifyLicenseTypeConfirm(licenseData) {
    const { dispatch } = this.props;
    try {
      dispatch(ModalsActions.hideModal('MODIFY_LICENSE_REQUEST_MODAL'));
      dispatch(ModalsActions.hideModal('MODIFY_LICENSE_MODAL'));
      dispatch(UtilsActions.setSpinnerVisible(true));
      await dispatch(DomainActions.updateDomainType({ domainType: licenseData.domainType }));
      dispatch(
        ModalsActions.showModal('CREDIT_SUCCESS_MODAL', {
          modalType: 'SUCCESS_ALERT',
          modalProps: {
            message: translations.t('forms.modifyDomainTypeSuccess'),
          },
        }),
      );
      dispatch(UtilsActions.setSpinnerVisible(false));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisible(false));
      dispatch(
        ModalsActions.showModal('ADD_CREDIT_ERROR_MODAL', {
          modalType: 'ERROR_ALERT',
          modalProps: {
            message: translations.t('forms.modifyDomainTypeError'),
          },
        }),
      );
    }
  }

  onModifyDomainStatusRequest() {
    const { dispatch } = this.props;
    dispatch(
      ModalsActions.showModal('MODIFY_DOMAIN_STATUS_REQUEST_MODAL', {
        modalType: 'MODAL_DIALOG',
        modalProps: {
          title: translations.t('forms.warning'),
          bodyTextStyle: { fontSize: 18 },
          bodyText: translations.t('forms.disableDomainRequest'),
          onConfirm: () => this.onModifyDomainStatusConfirm(),
        },
      }),
    );
  }

  async onModifyDomainStatusConfirm() {
    const { dispatch, domainData } = this.props;
    try {
      const currentStatus = domainData.active;
      dispatch(ModalsActions.hideModal('MODIFY_DOMAIN_STATUS_REQUEST_MODAL'));
      dispatch(UtilsActions.setSpinnerVisible(true));
      await dispatch(DomainActions.updateDomainEnableStatus({ enabled: !currentStatus }));
      dispatch(
        ModalsActions.showModal('CREDIT_SUCCESS_MODAL', {
          modalType: 'SUCCESS_ALERT',
          modalProps: {
            message: translations.t('forms.modifyDomainStatusSuccess'),
          },
        }),
      );
      dispatch(UtilsActions.setSpinnerVisible(false));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisible(false));
      dispatch(
        ModalsActions.showModal('ADD_CREDIT_ERROR_MODAL', {
          modalType: 'ERROR_ALERT',
          modalProps: {
            message: translations.t('forms.modifyDomainStatusError'),
          },
        }),
      );
    }
  }

  onCustomFieldDeleteRequest(customField) {
    const { dispatch } = this.props;
    dispatch(
      ModalsActions.showModal('DELETE_CUSTOMFIELD_REQUEST_MODAL', {
        modalType: 'MODAL_DIALOG',
        modalProps: {
          title: translations.t('forms.warning'),
          bodyTextStyle: { fontSize: 18 },
          bodyText: translations.t('domain.deleteCustomFieldRequest'),
          onConfirm: () => this.onCustomFieldDeleteConfirm(customField),
        },
      }),
    );
  }

  onShowCustomFieldModal(customFieldData) {
    const { dispatch } = this.props;
    const customFieldInitialValues = customFieldData || { customFieldType: 'STRING' };
    dispatch(initialize('CustomFieldForm', customFieldInitialValues));
    dispatch(
      ModalsActions.showModal('CUSTOM_FIELD_MODAL', {
        modalType: 'MODAL_DIALOG',
        modalProps: {
          title: translations.t('domain.customFields'),
          hideCancel: true,
          content: <CustomFieldForm onSubmit={(data) => this.onCustomFieldSubmit(data)} />,
          onConfirm: () => dispatch(submit('CustomFieldForm')),
          confirmText: translations.t('modals.confirm'),
        },
      }),
    );
  }

  async onCustomFieldSubmit(customFieldData) {
    const { dispatch } = this.props;
    try {
      dispatch(ModalsActions.hideModal('CUSTOM_FIELD_MODAL'));
      dispatch(UtilsActions.setSpinnerVisible(true));
      if (customFieldData.id) {
        await dispatch(DomainActions.editCustomField(customFieldData));
      } else {
        await dispatch(DomainActions.createCustomField(customFieldData));
      }
      dispatch(
        ModalsActions.showModal('CUSTOM_FIELDS_SUCCESS_MODAL', {
          modalType: 'SUCCESS_ALERT',
          modalProps: {
            message: translations.t('domain.customFieldSuccess'),
          },
        }),
      );
      dispatch(UtilsActions.setSpinnerVisible(false));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisible(false));
      dispatch(
        ModalsActions.showModal('CUSTOM_FIELDS_ERROR_MODAL', {
          modalType: 'ERROR_ALERT',
          modalProps: {
            message: translations.t('domain.customFieldError'),
          },
        }),
      );
    }
  }

  async onCustomFieldDeleteConfirm(customField) {
    const { dispatch } = this.props;
    try {
      dispatch(ModalsActions.hideModal('DELETE_CUSTOMFIELD_REQUEST_MODAL'));
      dispatch(UtilsActions.setSpinnerVisible(true));
      await dispatch(DomainActions.deleteCustomField(customField));
      dispatch(
        ModalsActions.showModal('CUSTOM_FIELDS_SUCCESS_MODAL', {
          modalType: 'SUCCESS_ALERT',
          modalProps: {
            message: translations.t('domain.customFieldDeleteSuccess'),
          },
        }),
      );
      dispatch(UtilsActions.setSpinnerVisible(false));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisible(false));
      dispatch(
        ModalsActions.showModal('CUSTOM_FIELDS_ERROR_MODAL', {
          modalType: 'ERROR_ALERT',
          modalProps: {
            message: translations.t('domain.customFieldDeleteError'),
          },
        }),
      );
    }
  }

  render() {
    const { classes, domainData } = this.props;
    return (
      <Card className={classes.cardContainer}>
        <div className={classes.domainEnableContainer}>
          <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
            <h4 className={classes.title}>{translations.t('forms.domainStatus')}:</h4>
            <Chip
              color="#F57D24"
              label={domainData.active ? translations.t('forms.enabled') : translations.t('forms.disabled')}
              style={{ backgroundColor: '#F57D24', color: 'white', paddingLeft: 10, paddingRight: 10, marginLeft: 20 }}
            />
          </div>
          <Permission
            do={[PERMISSIONS.DELETE]}
            on={PERMISSION_ENTITIES.DOMAIN}
            abilityHelper={AbilityProvider.getDomainAbilityHelper()}
          >
            <MDButton
              title={domainData.active ? translations.t('forms.disableDomain') : translations.t('forms.enableDomain')}
              backgroundColor={domainData.active ? RED : EDULAI_BLUE}
              containerstyle={{ marginBottom: 20, marginLeft: 50 }}
              onClick={() => this.onModifyDomainStatusRequest()}
            />
          </Permission>
        </div>
        <Divider style={{ height: 2, marginTop: 10 }} />
        <div className={classes.domainTypeContainer}>
          <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'center' }}>
            <h4 className={classes.title}>{translations.t('forms.subscriptionType')}:</h4>
            <h1 style={{ fontWeight: 800, color: EDULAI_PURPLE, marginLeft: 20 }}>
              {translations.t(`domainTypes.${domainData.domainType}`)}
            </h1>
          </div>
          <Permission
            do={[PERMISSIONS.DELETE]}
            on={PERMISSION_ENTITIES.DOMAIN}
            abilityHelper={AbilityProvider.getDomainAbilityHelper()}
          >
            <MDButton
              title={translations.t('forms.modifyDomainType')}
              backgroundColor={ORANGE}
              containerstyle={{ marginBottom: 20, marginLeft: 50 }}
              onClick={() => this.onOpenLicenseTypeModal()}
            />
          </Permission>
        </div>
        <Divider style={{ height: 2, marginTop: 20 }} />
        <div className={classes.creditsContainer} style={{ marginTop: 20 }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <h4 className={classes.title}>{translations.t('forms.credits')}:</h4>
            <div style={{ width: '20%', marginLeft: 20 }}>
              <CircularProgressbar
                value={domainData.credits}
                text={`${domainData.credits}`}
                styles={buildStyles({ pathColor: EDULAI_BLUE, textColor: EDULAI_BLUE })}
              />
            </div>
          </div>
          {domainData.domainType && domainData.domainType !== 'FLAT' && (
            <div>
              <Permission
                do={[PERMISSIONS.DELETE]}
                on={PERMISSION_ENTITIES.DOMAIN}
                abilityHelper={AbilityProvider.getDomainAbilityHelper()}
              >
                <MDButton
                  title={translations.t('forms.modifyCredits')}
                  backgroundColor={EDULAI_BLUE}
                  containerstyle={{ marginBottom: 20, marginLeft: 50 }}
                  onClick={() => this.onShowCreditsModal()}
                />
              </Permission>
            </div>
          )}
        </div>
        <Divider style={{ height: 2, marginTop: 20 }} />
        {domainData.domainType && domainData.domainType === 'FLAT' && (
          <div className={classes.warningContainer}>
            <InfoIcon style={{ color: EDULAI_BLUE }} />
            <h4 className={classes.subtitle} style={{ marginLeft: 10 }}>
              {translations.t('forms.flatCreditsDescription')}
            </h4>
          </div>
        )}
        <Permission
          do={[PERMISSIONS.UPDATE]}
          on={PERMISSION_ENTITIES.DOMAIN}
          abilityHelper={AbilityProvider.getDomainAbilityHelper()}
        >
          <div>
            <h2 style={{ color: EDULAI_PURPLE, marginTop: 20 }}>{translations.t('domain.customFields')}</h2>
            <MDButton
              title={translations.t('domain.createCustomField')}
              backgroundColor={EDULAI_BLUE}
              containerstyle={{ display: 'flex', justifyContent: 'center' }}
              buttonStyle={{ width: '30%' }}
              onClick={() => this.onShowCustomFieldModal()}
            />
            {_.map(domainData.customFields, (customField) => (
              <CustomFieldRow
                customField={customField}
                onClick={() => this.onShowCustomFieldModal(customField)}
                onDelete={() => this.onCustomFieldDeleteRequest(customField)}
              />
            ))}
          </div>
        </Permission>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  language: state.utils.selectedLanguage,
});

export default connect(mapStateToProps)(withStyles(styles)(DomainInfoView));
