import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import Chip from '@material-ui/core/Chip';
import { createMuiTheme, MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import React from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import translations from '../../../translations/i18next';
import { EDULAI_ORANGE, EDULAI_PURPLE } from '../../../styles/styleConsts';

const theme = createMuiTheme({
  palette: {
    primary: { 500: EDULAI_PURPLE },
    seconday: EDULAI_PURPLE,
  },
  typography: {
    useNextVariants: true,
  },
});

const styles = () => ({
  outerContainer: {
    marginBottom: 15,
    position: 'relative',
  },
  eventContainer: {
    padding: 15,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  typography: {
    useNextVariants: true,
  },
  titleContainer: {
    alignItems: 'center',
  },
  title: {
    margin: 0,
    textAlign: 'left',
    fontWeight: 800,
    fontSize: 18,
    color: EDULAI_PURPLE,
  },
  dateText: {
    margin: 0,
    color: '#979da1',
    fontSize: 15,
    marginTop: 5,
  },
  disabledText: {
    margin: 0,
    marginTop: 5,
    fontSize: 12,
    color: 'red',
    position: 'absolute',
    right: 10,
    top: 10,
  },
  body: {
    margin: 0,
    marginTop: 10,
    color: '#979da1',
    textAlign: 'left',
    fontSize: 12,
  },
  subSkillTag: {
    marginRight: 10,
    paddingLeft: 10,
    paddingRight: 10,
    marginTop: 15,
    backgroundColor: EDULAI_ORANGE,
    color: 'white',
  },
  subtitle: {
    margin: 0,
    wordBreak: 'break-all',
    fontWeight: '100',
  },
  skillsContainer: {
    marginBottom: 20,
  },
});

const QuestionRow = ({ question, onClick, classes, containerstyle }) => (
  <MuiThemeProvider theme={theme}>
    <Card style={{ ...containerstyle }} className={classes.outerContainer}>
      <CardActionArea className={classes.eventContainer} onClick={() => onClick()}>
        <div>
          <h3 className={classes.title}>{`${question.name}`}</h3>
          <h3 className={classes.subtitle} style={{ marginTop: 2 }}>
            {`${translations.t('questions.version')} ${question.version}`}
          </h3>
          {question.measuredSkills && !_.isEmpty(question.measuredSkills) && (
            <div className={classes.skillsContainer}>
              {_.map(question.measuredSkills, (skill) => (
                <Chip key={skill.name} className={classes.subSkillTag} label={skill.name} />
              ))}
            </div>
          )}
        </div>
        <h3 className={classes.subtitle}>{`${question.body}`}</h3>
      </CardActionArea>
    </Card>
  </MuiThemeProvider>
);

export default withStyles(styles)(withRouter(QuestionRow));
