import React from 'react';
import { Card, LinearProgress, withStyles } from '@material-ui/core';
import translations from '../../translations/i18next';
import { EDULAI_BLUE, EDULAI_ORANGE, EDULAI_PURPLE } from '../../styles/styleConsts';
import bestUserIcon from '../../assets/bestUser.png';
import worstUserIcon from '../../assets/worstUser.png';

export const CustomLinearProgress = withStyles({
  root: {},
  colorPrimary: {
    backgroundColor: 'rgba(77, 102, 185, 0.4)'
  },
  bar: {
    backgroundColor: EDULAI_BLUE
  }
})(LinearProgress);

const BestLeastPerformingUsers = ({ bestUser, badUser }) => {
  const isSameUser = bestUser && badUser && badUser.id === bestUser.id;
  return (
    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', marginTop: 20 }}>
      {bestUser && bestUser.name && (
        <Card
          style={{
            borderRadius: 8,
            padding: 20,
            width: '50%',
            backgroundColor: 'rgba(245, 125, 36, 0.12)',
            boxShadow: 'none',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            marginRight: 10
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flex: 1,
              justifyContent: 'flex-start',
              alignItems: 'center'
            }}
          >
            <img alt="evaluation" src={bestUserIcon} style={{ width: 50, height: 50 }} />
            <h3 style={{ margin: 0, fontSize: 20, marginLeft: 10, color: EDULAI_ORANGE }}>
              {translations.t('analytics.bestPerformingUser').toUpperCase()}
            </h3>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 40 }}>
            <h2 style={{ margin: 0, fontWeight: 800, color: '#111827' }}>{`${bestUser.name} ${bestUser.surname}`}</h2>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              flex: 1,
              width: '100%'
            }}
          >
            <h1 style={{ color: EDULAI_PURPLE, margin: 0 }}>{bestUser.score}</h1>
            <CustomLinearProgress
              variant="determinate"
              value={(bestUser.score / 5) * 100}
              style={{ width: '100%', color: EDULAI_PURPLE, marginBottom: 10 }}
            />
          </div>
        </Card>
      )}
      {!isSameUser && badUser && badUser.name && (
        <Card
          style={{
            borderRadius: 8,
            padding: 20,
            width: '50%',
            backgroundColor: 'rgba(77, 102, 185, 0.08)',
            boxShadow: 'none',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            marginLeft: 10
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flex: 1,
              justifyContent: 'flex-start',
              alignItems: 'center'
            }}
          >
            <img alt="evaluation" src={worstUserIcon} style={{ width: 40, height: 40 }} />
            <h3 style={{ margin: 0, fontSize: 20, marginLeft: 10, color: EDULAI_PURPLE }}>
              {translations.t('analytics.leastPerformingUser').toUpperCase()}
            </h3>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 40 }}>
            <h2 style={{ margin: 0, fontWeight: 800, color: '#111827' }}>{`${badUser.name} ${badUser.surname}`}</h2>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              flex: 1,
              width: '100%'
            }}
          >
            <h1 style={{ color: EDULAI_PURPLE, margin: 0 }}>{badUser.score}</h1>
            <CustomLinearProgress
              variant="determinate"
              value={(badUser.score / 5) * 100}
              style={{ width: '100%', color: EDULAI_PURPLE, marginBottom: 10 }}
            />
          </div>
        </Card>
      )}
    </div>
  );
};

export default BestLeastPerformingUsers;
