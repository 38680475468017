import { withStyles } from '@material-ui/core';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { QUIZ_STATUS_TYPES, SUBMISSION_STATUS_TYPES } from '../../config/configurations';
import * as QuestionsActions from '../../redux/actions/questions.actions';
import * as AnswersActions from '../../redux/actions/answers.actions';
import * as SubmissionsActions from '../../redux/actions/submissions.actions';
import { EDULAI_BLUE, GREEN_LIGHT } from '../../styles/styleConsts';
import translations from '../../translations/i18next';
import MDButton from '../MDButton/MDButton';
import Spinner from '../Spinner/Spinner';
import QuizSumissionLinearStepper from '../Stepper/QuizSumissionLinearStepper';
import BaseQuizStatusView from '../QuizStatusesViews/BaseQuizStatusView';
import UserAnswers from '../Analytics/UserAnswers';
import QuizUserAnalytics from '../Analytics/QuizUserAnalytics';
import MDAccordion from '../MDAccordion/MDAccordion';
import QuizSubmissionReady from './QuizSubmissionReady';
import * as UtilsActions from '../../redux/actions/utils.actions';
import { generateAndDownloadPdf } from '../../utils/PDF/PdfUtils';
import moment from 'moment';
import { isMobileBrowser } from '../../utils/utilsFunctions';

const styles = () => ({
  container: {
    height: '100%',
    overflow: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
});

const theme = createMuiTheme({
  palette: {
    primary: { 500: EDULAI_BLUE },
    secondary: { main: '#CC0033' }
  },
  typography: {
    useNextVariants: true
  }
});

class QuizSubmission extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      error: false
    };
  }

  async componentDidMount() {
    const { dispatch } = this.props;
    try {
      await dispatch(QuestionsActions.fetchQuestions());
      try {
        await dispatch(SubmissionsActions.fetchSubmissionsByUser());
      } catch (error) {}
      await dispatch(AnswersActions.fetchAnswers());
      this.setState({ isLoading: false });
    } catch (error) {
      this.setState({ isLoading: false, error: true });
    }
  }

  async startNewSubmission() {
    const { dispatch } = this.props;
    this.setState({ isLoading: true });
    try {
      await dispatch(SubmissionsActions.startNewSubmission());
      try {
        await dispatch(SubmissionsActions.fetchSubmissionsByUser());
      } catch (error) {}
      this.setState({ isLoading: false });
    } catch (error) {
      this.setState({ isLoading: false, error: true });
    }
  }

  async createPDF(user) {
    this.props.dispatch(UtilsActions.setSpinnerVisible(true));
    const data = {
      name: user.name + ' ' + user.surname,
      date: moment().format('LLL')
    };
    const elementIds = ['test-all-skills'];

    await generateAndDownloadPdf(data, [`chart-${user.id}`, `skills-description-${user.id}`], elementIds);
    this.props.dispatch(UtilsActions.setSpinnerVisible(false));
  }

  render() {
    const {
      classes,
      quizzes: { selectedQuiz: quiz },
      submissions: {
        data: { user: quizSubmissionsData }
      },
      onFinishQuiz,
      user,
      institutionType
    } = this.props;

    const { isLoading, error } = this.state;

    const quizSubmission = _.find(quizSubmissionsData, { quizId: quiz.id });
    const userSubmission = _.find(quizSubmissionsData, { ownerId: user.id, quizId: quiz.id });
    const hasPendingSubmissions =
      quizSubmission && quizSubmission.submissionStatusType === SUBMISSION_STATUS_TYPES.DRAFT;
    const hasTerminateAnswers = quiz && quiz.answers && _.size(quiz.answers) === _.size(quiz.questionsIds);
    const isPendingLastSubmissions =
      hasPendingSubmissions && hasTerminateAnswers && quiz.quizStatusType === QUIZ_STATUS_TYPES.RUNNING;

    return (
      <MuiThemeProvider theme={theme}>
        <div className={classes.container}>
          {isLoading ? (
            <Spinner
              customStyle={{ margin: 'auto' }}
              title={translations.t('general.loading')}
              hideLogo
              spinnerStyle={{ color: EDULAI_BLUE, marginTop: 10 }}
              titleStyle={{ color: '#80888d', marginTop: 5, fontSize: 12 }}
            />
          ) : null}
          {!isLoading && error ? (
            <div style={{ margin: 'auto', textAlign: 'center' }}>
              <h1>{translations.t('submissions.submissionLoadFailed')}</h1>
              <h2>{translations.t('submissions.pleaseReload')}</h2>
            </div>
          ) : null}
          {!isLoading && <BaseQuizStatusView quiz={quiz} quizSubmission={quizSubmission} />}
          {!isLoading && !error && quiz.quizStatusType === QUIZ_STATUS_TYPES.RUNNING && !quizSubmission ? (
            <QuizSubmissionReady
              quiz={quiz}
              institutionType={institutionType}
              onStart={() => this.startNewSubmission()}
            />
          ) : null}
          {!isLoading &&
          !error &&
          quiz.quizStatusType === QUIZ_STATUS_TYPES.RUNNING &&
          quizSubmission &&
          quizSubmission.submissionStatusType === SUBMISSION_STATUS_TYPES.DRAFT &&
          !isPendingLastSubmissions ? (
            <QuizSumissionLinearStepper questions={quiz.questions} onFinishQuiz={() => onFinishQuiz()} />
          ) : null}
          {!isLoading && !error && isPendingLastSubmissions ? (
            <div style={{ textAlign: 'center', margin: 'auto' }}>
              <h3 style={{ textAlign: 'center', fontWeight: 'normal' }}>
                {translations.t('submissions.submissionCompleted')}
              </h3>
              <MDButton
                title={translations.t('submissions.concludeQuiz')}
                backgroundColor={GREEN_LIGHT}
                containerstyle={{ marginLeft: 30 }}
                onClick={() => onFinishQuiz()}
              />
            </div>
          ) : null}
          {!isLoading &&
          !error &&
          (quiz.quizStatusType === QUIZ_STATUS_TYPES.RUNNING || quiz.quizStatusType === QUIZ_STATUS_TYPES.DONE) &&
          quizSubmission &&
          quizSubmission.submissionStatusType === SUBMISSION_STATUS_TYPES.SUBMITTED ? (
            <div style={{ paddingLeft: 150, paddingRight: 150 }}>
              <h1 style={{ marginTop: 0, color: 'white', marginLeft: isMobileBrowser() ? 10 : 0 }}>{quiz.name}</h1>
              <QuizUserAnalytics
                hideSubSkills
                quiz={quiz.id}
                user={user.id}
                containerStyle={{ backgroundColor: 'white', borderRadius: 8, padding: 20 }}
                onDownloadReport={() => this.createPDF(user)}
                canDownloadReport={false}
              />
              <MDAccordion
                defaultExpanded
                title={translations.t('questions.myAnswers')}
                containerstyle={{ marginTop: 20, marginBottom: 20, borderRadius: 8 }}
                accordionStyle={{ borderRadius: 8 }}
                titleStyle={{ fontSize: 20 }}
              >
                <UserAnswers quiz={quiz} submission={userSubmission} />
              </MDAccordion>
            </div>
          ) : null}
        </div>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  quizzes: state.quizzes,
  questions: state.questions,
  submissions: state.submissions,
  user: state.user.data,
  institutionType: state.user.institutionType,
  skills: state.skills
});

export default connect(mapStateToProps)(withStyles(styles)(QuizSubmission));
