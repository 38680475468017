import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import { createMuiTheme, MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import StatisticsIcon from '@material-ui/icons/Timeline';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import { connect } from 'react-redux';

import {
  PERMISSION_ENTITIES,
  PERMISSIONS,
  QUIZ_STATUS_TYPES,
  SUBMISSION_STATUS_TYPES,
} from '../../../config/configurations';
import { EDULAI_BLUE, EDULAI_PURPLE } from '../../../styles/styleConsts';
import translations from '../../../translations/i18next';
import AbilityProvider from '../../../utils/PermissionsUtils/AbilityProvider';
import MDButton from '../../MDButton/MDButton';
import Permission from '../../Permission/Permission';
import { isMobileBrowser } from '../../../utils/utilsFunctions';
import QuizChip from '../../Chips/QuizChip';

const theme = createMuiTheme({
  palette: {
    primary: { 500: EDULAI_PURPLE },
    seconday: EDULAI_PURPLE,
  },
  typography: {
    useNextVariants: true,
  },
});

const styles = () => ({
  outerContainer: {
    marginBottom: 15,
    position: 'relative',
    borderRadius: 8,
    height: 280,
  },
  eventContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  typography: {
    useNextVariants: true,
  },
  titleContainer: {
    alignItems: 'center',
  },
  title: {
    margin: 0,
    textAlign: 'left',
    fontWeight: 'bolder',
    fontSize: 32,
    color: EDULAI_PURPLE,
  },
  dateText: {
    margin: 0,
    color: '#979da1',
    fontSize: 15,
    marginTop: 5,
  },
  disabledText: {
    margin: 0,
    marginTop: 5,
    fontSize: 12,
    color: 'red',
    position: 'absolute',
    right: 10,
    top: 10,
  },
  body: {
    margin: 0,
    marginTop: 10,
    color: '#979da1',
    textAlign: 'left',
    fontSize: 12,
  },
  text: {
    fontWeight: 'normal',
    margin: 0,
    marginTop: 0,
  },
  status: {
    display: 'flex',
    margin: 'auto',
  },
  statusText: {
    textAlign: 'center',
    color: 'white',
    margin: 'auto',
    fontWeight: 800,
    fontSize: 18,
  },
});

const get12Or24HrsFormat = () => {
  const LTFormat = moment.localeData().longDateFormat('LT');
  if (LTFormat.split(' ').length > 1 && LTFormat.split(' ').pop() === 'A') {
    return 'MMMM D YYYY h:mm a';
  }
  return 'MMMM D YYYY H:mm';
};

const QuizRow = (props) => {
  const { quiz, onClick, classes, containerstyle, answers, isBaseUser, onFinishQuiz, onShowStats, userId } = props;

  // Submissions
  const completedSubmissionsOverall = _.filter(
    quiz.submissions,
    (submission) => submission.submissionStatusType === SUBMISSION_STATUS_TYPES.SUBMITTED,
  );

  const pendingSubmissions = _.filter(
    quiz.submissions,
    (submission) => submission.ownerId === userId && submission.submissionStatusType === SUBMISSION_STATUS_TYPES.DRAFT,
  );

  // Partecipants
  const participantsCount = quiz && quiz.participantsIds ? _.size(quiz.participantsIds) : null;

  const completedCount = completedSubmissionsOverall ? _.size(completedSubmissionsOverall) : null;
  const givenAnswers = answers ? _.size(answers) : null;
  const dateTimeFormat = get12Or24HrsFormat();
  const completionAnswerPercentage =
    givenAnswers && quiz && quiz.questionsIds ? Math.floor((givenAnswers / _.size(quiz.questionsIds)) * 100) : 0;
  const completionSubmissionsPercentage =
    completedCount && participantsCount ? Math.floor((completedCount / participantsCount) * 100) : 0;
  const percentageToShow = isBaseUser ? completionAnswerPercentage : completionSubmissionsPercentage;
  const isPendingLastSubmissions =
    pendingSubmissions &&
    !_.isEmpty(pendingSubmissions) &&
    completionAnswerPercentage === 100 &&
    quiz.quizStatusType === QUIZ_STATUS_TYPES.RUNNING;

  const isQuizRunningAndOver =
    quiz.quizStatusType === QUIZ_STATUS_TYPES.RUNNING &&
    _.isEmpty(pendingSubmissions) &&
    completionAnswerPercentage === 100;

  const quizStatusToShow = isBaseUser && isQuizRunningAndOver ? QUIZ_STATUS_TYPES.DONE : quiz.quizStatusType;

  const isQuizStarted =
    quiz.quizStatusType !== QUIZ_STATUS_TYPES.DRAFT && quiz.quizStatusType !== QUIZ_STATUS_TYPES.READY;

  return (
    <MuiThemeProvider theme={theme}>
      <Card style={{ ...containerstyle }} className={classes.outerContainer}>
        <CardActionArea className={classes.eventContainer} onClick={() => onClick()}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              backgroundColor: 'white',
              alignItems: 'center',
              padding: 20,
            }}
          >
            <CircularProgressbar
              value={percentageToShow}
              text={`${percentageToShow}%`}
              styles={buildStyles({ pathColor: EDULAI_BLUE, textColor: EDULAI_BLUE })}
            />
          </div>
          <div
            style={{
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
              backgroundColor: 'white',
              height: '100%',
              width: '100%',
              marginLeft: 20,
            }}
          >
            <div
              style={{
                width: isMobileBrowser() ? '60%' : '80%',
                marginTop:
                  AbilityProvider.getDomainAbilityHelper().hasPermission(
                    [PERMISSIONS.UPDATE],
                    PERMISSION_ENTITIES.QUIZ,
                  ) ||
                  (givenAnswers && isBaseUser)
                    ? 60
                    : 0,
              }}
            >
              <h1 className={classes.title}>{quiz.name}</h1>
              <h5 className={classes.text}>
                {`${moment(quiz.startDate).format(dateTimeFormat)} - ${moment(quiz.endDate).format(dateTimeFormat)}`}
              </h5>
              <div style={{ backgroundColor: 'white', marginTop: 30 }}>
                <Permission
                  do={[PERMISSIONS.UPDATE]}
                  on={PERMISSION_ENTITIES.QUIZ}
                  abilityHelper={AbilityProvider.getDomainAbilityHelper()}
                >
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <div
                      style={{
                        width: '50%',
                        display: 'inline-flex',
                        flexDirection: 'row',
                        alignItems: 'baseline',
                      }}
                    >
                      <p style={{ fontSize: 14 }} className={classes.text}>{`${translations.t(
                        'quizzes.participants',
                      )}:`}</p>
                      <p
                        style={{ fontSize: 32, fontWeight: 'bold', color: EDULAI_PURPLE, marginLeft: 5 }}
                        className={classes.text}
                      >{`${participantsCount}`}</p>
                    </div>
                    <div style={{ width: '50%', display: 'inline-flex', flexDirection: 'row', alignItems: 'baseline' }}>
                      <p style={{ fontSize: 14 }} className={classes.text}>{`${translations.t(
                        'quizzes.completed',
                      )}:`}</p>
                      <p
                        style={{ fontSize: 32, fontWeight: 'bold', color: EDULAI_PURPLE, marginLeft: 5 }}
                        className={classes.text}
                      >{`${completedCount}`}</p>
                    </div>
                  </div>
                </Permission>
                {givenAnswers && isBaseUser ? (
                  <div style={{ display: 'flex' }}>
                    <div style={{ width: '50%', display: 'inline-flex', flexDirection: 'row', alignItems: 'baseline' }}>
                      <p style={{ fontSize: 14 }} className={classes.text}>{`${translations.t(
                        'quizzes.givenAnswers',
                      )}:`}</p>
                      <p
                        style={{ fontSize: 32, fontWeight: 'bold', color: EDULAI_PURPLE, marginLeft: 5 }}
                        className={classes.text}
                      >{`${givenAnswers}`}</p>
                    </div>
                    {quiz && quiz.questionsIds && (
                      <div
                        style={{ width: '50%', display: 'inline-flex', flexDirection: 'row', alignItems: 'baseline' }}
                      >
                        <p style={{ fontSize: 14 }} className={classes.text}>
                          {`${translations.t('quizzes.totalQuestions')}:`}
                        </p>
                        <p
                          style={{ fontSize: 32, fontWeight: 'bold', color: EDULAI_PURPLE, marginLeft: 5 }}
                          className={classes.text}
                        >
                          {`${_.size(quiz.questionsIds)}`}
                        </p>
                      </div>
                    )}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </CardActionArea>
        <div style={{ position: 'absolute', right: 15, top: 15 }}>
          <div className={classes.status}>
            <QuizChip status={quizStatusToShow} />
          </div>
        </div>
        {isQuizStarted && (
          <Permission
            do={[PERMISSIONS.UPDATE]}
            on={PERMISSION_ENTITIES.QUIZ}
            abilityHelper={AbilityProvider.getDomainAbilityHelper()}
          >
            <div style={{ position: 'absolute', right: 0, bottom: 0 }}>
              <MDButton
                iconEnd={<StatisticsIcon style={{ marginLeft: 15 }} />}
                title={translations.t('quizzes.quizStatistics')}
                backgroundColor={EDULAI_BLUE}
                containerstyle={{ marginTop: 0, marginRight: 15, marginBottom: 15 }}
                buttonStyle={{ borderRadius: 8 }}
                onClick={() => onShowStats()}
              />
            </div>
          </Permission>
        )}
        {isPendingLastSubmissions && isBaseUser && (
          <MDButton
            title={translations.t('submissions.concludeQuiz')}
            backgroundColor={EDULAI_BLUE}
            containerstyle={{ margin: 10, marginLeft: 40, marginRight: 40 }}
            onClick={() => onFinishQuiz()}
          />
        )}
      </Card>
    </MuiThemeProvider>
  );
};

const mapStateToProps = (state) => ({
  language: state.utils.selectedLanguage,
});

export default connect(mapStateToProps)(withStyles(styles)(QuizRow));
