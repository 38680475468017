import React from 'react';
import { EDULAI_BLUE } from '../../styles/styleConsts';
import { PictureAsPdfRounded } from '@material-ui/icons';
import { Button, createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import { isMobileBrowser } from '../../utils/utilsFunctions';

const theme = createMuiTheme({
  palette: {
    primary: { 500: EDULAI_BLUE }
  },
  typography: {
    useNextVariants: true
  }
});

const DownloadPdfButton = ({ onClick }) => (
  <MuiThemeProvider theme={theme}>
    {isMobileBrowser() ? null : (
      <Button
        variant="text"
        color="primary"
        startIcon={<PictureAsPdfRounded style={{ color: EDULAI_BLUE, fontSize: 30 }} />}
        onClick={onClick}
      >
        Download PDF
      </Button>
    )}
  </MuiThemeProvider>
);

export default DownloadPdfButton;
