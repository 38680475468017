import { withStyles } from '@material-ui/core';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import Stepper from '@material-ui/core/Stepper';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { destroy, initialize, submit } from 'redux-form';
import * as QuizzesActions from '../../redux/actions/quizzes.actions';
import * as AnswerActions from '../../redux/actions/answers.actions';
import * as ModalsActions from '../../redux/actions/modal.actions';
import * as UtilsActions from '../../redux/actions/utils.actions';
import { EDULAI_BLUE, GREEN_LIGHT } from '../../styles/styleConsts';
import translations from '../../translations/i18next';
import SubmissionAnswerForm from '../Forms/SubmissionAnswerForm/SubmissionAnswerForm';
import MDButton from '../MDButton/MDButton';

const styles = (theme) => ({
  root: {
    width: '100%',
    backgroundColor: 'white',
    marginTop: 20,
    borderRadius: 8,
    padding: 30,
  },
  button: {
    marginRight: theme.spacing(1),
  },
  completed: {
    display: 'inline-block',
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    fontWeight: 'bold',
    textAlign: 'center',
  },
  buttonsDiv: {
    display: 'flex',
    alignItems: 'flex-end',
    flexGrow: 1,
    margin: 'auto',
    marginBottom: 30,
  },
  innerContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
});

const theme = createMuiTheme({
  palette: {
    primary: { 500: EDULAI_BLUE },
    seconday: '#009900',
  },
  typography: {
    useNextVariants: true,
  },
});

class QuizSumissionLinearStepper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
    };
  }

  componentDidMount() {
    const {
      questions,
      dispatch,
      answers: {
        data: { content: answersData },
      },
    } = this.props;

    const answered = _.map(answersData, (answer) => answer.questionId);
    let nextNotAnswered;
    if (!this.allStepsCompleted()) {
      const nextQuestion = _.min(
        _.filter(questions, (question) => !answered.includes(question.id)),
        ['id'],
      );
      nextNotAnswered = _.findIndex(questions, { id: nextQuestion.id });
    } else {
      nextNotAnswered = questions.length - 1;
    }
    const selectedAnswer = _.find(answersData, { questionId: questions[nextNotAnswered].id });
    dispatch(destroy('SubmissionAnswerForm'));
    if (selectedAnswer) {
      dispatch(
        initialize('SubmissionAnswerForm', {
          answer: selectedAnswer.optionNumber,
        }),
      );
    }
    this.setState({ activeStep: nextNotAnswered });
  }

  onSubmitAnswerHandler(questionId, values) {
    const { questions } = this.props;
    const { activeStep } = this.state;
    const isLastAnswer = activeStep + 1 === questions.length;
    if (isLastAnswer) {
      this.onSubmitLastAnswer(questionId, values);
    } else {
      this.onSubmitAnswer(questionId, values);
    }
  }

  onSubmitAnswer = async (questionId, values) => {
    const {
      dispatch,
      answers: {
        data: { content: answersData },
      },
      quiz,
    } = this.props;
    if (_.isEmpty(values)) {
      dispatch(
        ModalsActions.showModal('NO_ANSWER_SELECTED_ERROR_MODAL', {
          modalType: 'ERROR_ALERT',
          modalProps: {
            message: translations.t('submissions.noAnswerSelectedError'),
          },
        }),
      );
    } else {
      try {
        dispatch(UtilsActions.setSpinnerVisible(true));
        const currentAnswer = _.find(answersData, { questionId });
        if (currentAnswer) {
          await dispatch(AnswerActions.updateAnswer(questionId, values));
        } else {
          await dispatch(AnswerActions.createAnswer(questionId, values));
        }
        await dispatch(AnswerActions.fetchAnswers());
        dispatch(QuizzesActions.fetchQuizzAnswersAndSubmissions(quiz));
        this.containerDiv.scrollIntoView({ behavior: 'smooth' });
        dispatch(
          ModalsActions.showModal('ANSWER_SUBMIT_SUCCESS_MODAL', {
            modalType: 'SUCCESS_ALERT',
            modalProps: {
              message: translations.t('submissions.answerSubmitSuccess'),
            },
          }),
        );
        dispatch(destroy('SubmissionAnswerForm'));
        this.handleNext();
        dispatch(UtilsActions.setSpinnerVisible(false));
      } catch (error) {
        dispatch(
          ModalsActions.showModal('ANSWER_SUBMIT_ERROR_MODAL', {
            modalType: 'ERROR_ALERT',
            modalProps: {
              message: translations.t('submissions.answerSubmitError'),
            },
          }),
        );
        dispatch(UtilsActions.setSpinnerVisible(false));
      }
    }
  };

  onSubmitLastAnswer = async (questionId, values) => {
    const { dispatch, onFinishQuiz } = this.props;
    if (_.isEmpty(values)) {
      dispatch(
        ModalsActions.showModal('NO_ANSWER_SELECTED_ERROR_MODAL', {
          modalType: 'ERROR_ALERT',
          modalProps: {
            message: translations.t('submissions.noAnswerSelectedError'),
          },
        }),
      );
    } else {
      try {
        dispatch(UtilsActions.setSpinnerVisible(true));
        await dispatch(AnswerActions.createAnswer(questionId, values));
        onFinishQuiz();
        dispatch(destroy('SubmissionAnswerForm'));
      } catch (error) {
        dispatch(
          ModalsActions.showModal('ANSWER_SUBMIT_ERROR_MODAL', {
            modalType: 'ERROR_ALERT',
            modalProps: {
              message: translations.t('submissions.answerSubmitError'),
            },
          }),
        );
        dispatch(UtilsActions.setSpinnerVisible(false));
      }
    }
  };

  isCompleted = (questionId) => {
    const {
      answers: {
        data: { content: answersData },
      },
    } = this.props;
    return _.find(answersData, { questionId });
  };

  totalSteps = () => {
    const { questions } = this.props;
    return questions.length;
  };

  handleNext = () => {
    const {
      dispatch,
      questions,
      answers: {
        data: { content: answersData },
      },
    } = this.props;
    const { activeStep } = this.state;
    const nextQuestions = questions.slice(activeStep + 1);
    const answered = _.map(answersData, (answer) => answer.questionId);
    let nextNotAnswered;
    if (!this.allStepsCompleted()) {
      let nextQuestion = _.min(
        _.filter(questions, (question) => !answered.includes(question.id)),
        ['id'],
      );
      if (!_.isEmpty(nextQuestions)) {
        const nextQuestionTemp = _.min(
          _.filter(nextQuestions, (question) => !answered.includes(question.id)),
          ['id'],
        );
        if (nextQuestionTemp) {
          nextQuestion = nextQuestionTemp;
        }
      }
      nextNotAnswered = _.findIndex(questions, { id: nextQuestion.id });
    } else {
      nextNotAnswered = activeStep;
    }
    const selectedAnswer = _.find(answersData, { questionId: questions[nextNotAnswered].id });
    dispatch(destroy('SubmissionAnswerForm'));
    if (selectedAnswer) {
      dispatch(
        initialize('SubmissionAnswerForm', {
          ...selectedAnswer,
          answer: selectedAnswer.optionNumber,
        }),
      );
    }
    this.setState({ activeStep: nextNotAnswered });
  };

  handleBack = () => {
    this.setState({ activeStep: (prevActiveStep) => prevActiveStep - 1 });
  };

  allStepsCompleted = () => {
    const {
      questions,
      answers: {
        data: { content: answersData },
      },
    } = this.props;
    return questions.length === answersData.length;
  };

  render() {
    const { classes, questions, dispatch } = this.props;

    const { activeStep } = this.state;
    const options =
      questions && questions[activeStep] && questions[activeStep].options
        ? _.map(_.sortBy(questions[activeStep].options.optionDTOS, ['optionNumber']), (option) => ({
            id: option.optionNumber,
            value: option.body,
          }))
        : [];

    const isLastAnswer = activeStep + 1 === questions.length;

    return (
      <MuiThemeProvider theme={theme}>
        <div
          className={classes.root}
          ref={(c) => {
            this.containerDiv = c;
          }}
        >
          <div style={{ display: 'flex' }}>
            <Stepper style={{ margin: 'auto', width: '100%' }} nonLinear activeStep={activeStep}>
              {questions.map((question, index) => (
                <Step key={question.id}>
                  <StepButton completed={this.isCompleted(question.id)} />
                </Step>
              ))}
            </Stepper>
          </div>
          <div className={classes.innerContainer}>
            <h2>{questions[activeStep].name}</h2>
            <Typography className={classes.instructions}>{questions[activeStep].body}</Typography>
            <SubmissionAnswerForm
              options={options}
              onSubmit={(values) => this.onSubmitAnswerHandler(questions[activeStep].id, values)}
            />
            {isLastAnswer ? (
              <MDButton
                title={translations.t('submissions.concludeQuiz')}
                backgroundColor={GREEN_LIGHT}
                containerstyle={{
                  marginTop: 0,
                  marginBottom: 20,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                onClick={() => dispatch(submit('SubmissionAnswerForm'))}
                buttonStyle={{ width: '30%' }}
              />
            ) : (
              <MDButton
                title={translations.t('submissions.submitAnswer')}
                backgroundColor={EDULAI_BLUE}
                containerstyle={{
                  marginTop: 0,
                  marginBottom: 20,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                onClick={() => dispatch(submit('SubmissionAnswerForm'))}
                buttonStyle={{ width: '30%' }}
              />
            )}
          </div>
        </div>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  answers: state.answers,
  quiz: state.quizzes.selectedQuiz,
});

export default _.flow([connect(mapStateToProps), withStyles(styles)])(QuizSumissionLinearStepper);
