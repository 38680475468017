import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { EDULAI_PURPLE } from '../../styles/styleConsts';

const styles = (theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightRegular
  }
});

const MDAccordion = ({
  classes,
  containerstyle,
  subtitleStyle,
  defaultExpanded,
  panelStyle,
  titleStyle,
  title,
  subtitle,
  children,
  onChange,
  accordionStyle,
  testId,
  createReport
}) => {
  const [expanded, setExpanded] = useState(defaultExpanded || false);

  const onChangeExapanded = (e, isExpanded) => {
    if (onChange) {
      onChange(e, isExpanded);
    }
    setExpanded(isExpanded);
  };

  useEffect(() => {
    if (createReport === undefined) return;
    if (!createReport) {
      setExpanded(false);
    } else {
      setExpanded(true);
    }
  }, [createReport]);

  return (
    <div className={classes.root} style={{ ...containerstyle }} id={testId}>
      <ExpansionPanel
        onChange={onChangeExapanded}
        defaultExpanded={defaultExpanded}
        style={{ borderRadius: 8, ...accordionStyle }}
        expanded={expanded}
      >
        <ExpansionPanelSummary
          style={{ paddingLeft: 20 }}
          expandIcon={<ExpandMoreIcon style={{ color: EDULAI_PURPLE, fontSize: 40 }} />}
        >
          <div>
            <Typography className={classes.heading} style={{ color: '#3f3f3f', fontWeight: 'bold', ...titleStyle }}>
              {title}
            </Typography>
            {subtitle}
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: 10,
            ...panelStyle
          }}
        >
          {children}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
};

export default withStyles(styles)(MDAccordion);
