import _ from 'lodash';
import React from 'react';
import { EDULAI_PURPLE } from '../../styles/styleConsts';
import translations, { translationExists } from '../../translations/i18next';
import { connect } from 'react-redux';
import { ALL_SKILLS_MAP } from '../../config/configurations';
import { Translations } from '../../translations/Translations';

export function joinWithCommas(arr, lang = 'it') {
  const and = lang === 'it' ? ', e ' : ', and ';
  if (_.isEmpty(arr)) {
    return '';
  }
  if (arr.length === 1) {
    return arr[0];
  }
  return _.join(_.initial(arr), ', ') + and + _.last(arr);
}

const SubSkillDescription = ({ subSkills, userAnalytics, skill, lang }) => {
  const highScores = _.filter(subSkills, (skill) => {
    const subSkillValue = _.find(userAnalytics, { skillId: skill.id });
    return subSkillValue && subSkillValue.score >= 4;
  });

  const lowScores = _.filter(subSkills, (skill) => {
    const subSkillValue = _.find(userAnalytics, { skillId: skill.id });
    return subSkillValue && subSkillValue.score <= 2 && subSkillValue.score > 0;
  });

  const threeScoresList = _.filter(subSkills, (skill) => {
    const subSkillValue = _.find(userAnalytics, { skillId: skill.id });
    return subSkillValue && subSkillValue.score && Number(subSkillValue.score) === 3;
  });

  const skillTranslationKey = _.findKey(ALL_SKILLS_MAP, (sk) => sk === skill.name);

  return (
    <div style={{ marginLeft: 10 }}>
      <div>
        <h3 style={{ color: EDULAI_PURPLE }}>{translations.t(`skillsReport.strenghts`)}</h3>
        {highScores.length ? (
          <p style={{ fontSize: 15 }}>
            <Translations>
              {translations.t('skillsReport.subSkillStrenghtMessage', {
                subSkills: joinWithCommas(_.map(highScores, 'name'), lang)
              })}
            </Translations>
          </p>
        ) : (
          <p style={{ fontSize: 15 }}>{translations.t(`skillsReport.noStrenghts`)}</p>
        )}
      </div>
      <div>
        <h3 style={{ color: EDULAI_PURPLE }}>{translations.t(`skillsReport.weaknesses`)}</h3>
        {lowScores.length || threeScoresList.length ? (
          <p style={{ fontSize: 15 }}>
            <Translations>
              {lowScores.length !== 0
                ? translations.t(`skillsReport.subSkillWeaknessLowMessage`, {
                    subSkills: joinWithCommas(_.map(lowScores, 'name'), lang)
                  })
                : null}
            </Translations>
            <Translations>
              {threeScoresList.length !== 0
                ? translations.t(`skillsReport.subSkillWeaknessMediumMessage`, {
                    subSkills: joinWithCommas(_.map(threeScoresList, 'name'), lang)
                  })
                : null}
            </Translations>
          </p>
        ) : (
          <p style={{ fontSize: 15 }}>{translations.t(`skillsReport.noWeaknesses`)}</p>
        )}
      </div>
      {(lowScores.length || threeScoresList.length) && translationExists(`skillsReport.${skillTranslationKey}`) ? (
        <div>
          <h3 style={{ color: EDULAI_PURPLE }}>{translations.t(`skillsReport.areasDevelopments`)}</h3>
          <p style={{ fontSize: 15 }}>{translations.t(`skillsReport.${skillTranslationKey}`)}</p>
        </div>
      ) : null}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    lang: state.utils.selectedLanguage
  };
}

export default connect(mapStateToProps)(SubSkillDescription);
