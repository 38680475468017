export const JWT_ENCRIPTION_KEY = 'Z9EeqUBbTigwK2perL';
export const API_VERSION = 'v1';

export const getClientID = () => 'frontend';

export const getClientSecret = () => {
  switch (process.env.REACT_APP_PROFILE) {
    case 'production':
      return 'e1cb5bbee290519c092d588e574c99bc';
    default:
      return 'e1cb5bbee290519c092d588e574c99bc';
  }
};

// This function will determine the boominstance base URL depending on the build environment
export const getDefaultAPIBaseURL = () => 'http://localhost:8080/';

export const LANGUAGE_LOCAL_MAP = {
  ITALIAN: {
    key: 'it',
    momentLocale: 'it',
    translation: 'it',
    backend: 'ITALIAN'
  },
  ENGLISH: {
    key: 'en',
    momentLocale: 'en',
    translation: 'en',
    backend: 'ENGLISH'
  }
};

export const INSTITUTION_TYPES = {
  SMARTWORKING: 'SMARTWORKING'
};

export const LANGUAGE_BACKEND_MAP = {
  it: 'ITALIAN',
  en: 'ENGLISH'
};

export const ACCESS_RULES_TYPES = {
  DEFAULT: 'DEFAULT',
  USER: 'USER'
};

export const USER_ROLES = {
  ROOT: 'ROOT',
  ADMIN: 'ADMIN',
  SUPERVISOR: 'SUPERVISOR',
  USER: 'USER',
  PRINCIPAL: 'PRINCIPAL'
};

export const SKILLS_INSTRUCTION_LINKS = {
  REGULAR: {
    en: 'https://www.edulai.com/understand-the-results',
    it: 'https://www.edulai.com/capire-i-risultati'
  },
  SMARTWORKING: {
    en: 'https://www.edulai.com/smart-working-instructions',
    it: 'https://www.edulai.com/smart-working-instructions'
  }
};

export const PERMISSIONS = {
  READ: 'READ',
  CREATE: 'CREATE',
  DELETE: 'DELETE',
  UPDATE: 'UPDATE',
  EXTEND: 'EXTEND',
  REACTIVATE: 'REACTIVATE'
};

export const PERMISSION_ENTITIES = {
  USER: 'USER',
  DOMAIN: 'DOMAIN',
  QUIZ: 'QUIZ',
  QUESTION: 'QUESTION',
  SUBMISSION: 'SUBMISSION',
  ANSWER: 'ANSWER',
  SKILL: 'SKILL',
  ANALYTICS: 'ANALYTICS'
};

export const SUBMISSION_STATUS_TYPES = {
  DRAFT: 'DRAFT',
  SUBMITTED: 'SUBMITTED'
};

export const QUIZ_STATUS_TYPES = {
  DRAFT: 'DRAFT',
  RUNNING: 'RUNNING',
  PAUSED: 'PAUSED',
  DONE: 'DONE',
  READY: 'READY',
  ALL: 'ALL',
  EXPIRED_FOR_USER: 'EXPIRED_FOR_USER'
};

export const PRIVACY_POLICY_LINKS = {
  en: 'https://www.edulai.com/privacy-policy',
  it: 'https://it.edulai.com/privacy-policy'
};

export const SKILLS_MAP_SALES = {
  CRITICAL_THINKING: 'Pensiero critico',
  PROBLEM_SOLVING: 'Problem solving',
  LEARNING: 'Apprendimento',
  RESULT_ORIENTATION: 'Orientamento al risultato',
  ASSERTIVENESS: 'Assertività',
  RESILIENCE: 'Resilienza',
  COLLABORATION: 'Collaborazione',
  COMMUNICATION: 'Comunicazione',
  LEADERSHIP: 'Leadership'
};

export const SKILLS_MAP_SW = {
  DIGITAL_COLLABORATION: 'Collaborazione digitale',
  DIGITAL_COMMUNICATION: 'Comunicazione digitale',
  DIGITAL_PROBLEM_SOLVING: 'Problem Solving digitale',
  DIGITAL_LEADERSHIP: 'Leadership digitale',
  AUTONOMY: 'Autonomia',
  PROACTIVITY: 'Proattività',
  ASSERTIVENESS: 'Assertività',
  EMPATY: 'Empatia',
  RESILIENCE: 'Resilienza',
  DIGITAL_TECHNOLOGY: 'Tecnologie digitali'
};

export const SKILLS_MAP_STAGING = {
  COLLABORATION: 'Collaborazione',
  PROBLEM_SOLVING: 'Problem solving',
  LEADERSHIP: 'Leadership',
  COMMUNICATION: 'Comunicazione',
  INTERCULTURALITY: 'Interculturalità',
  LEARNING: 'Apprendimento',
  CRITICAL_THINKING: 'Pensiero critico'
};

export const ALL_SKILLS_MAP = {
  CRITICAL_THINKING: 'Pensiero critico',
  PROBLEM_SOLVING: 'Problem solving',
  LEARNING: 'Apprendimento',
  RESULT_ORIENTATION: 'Orientamento al risultato',
  ASSERTIVENESS: 'Assertività',
  RESILIENCE: 'Resilienza',
  COLLABORATION: 'Collaborazione',
  COMMUNICATION: 'Comunicazione',
  LEADERSHIP: 'Leadership',
  DIGITAL_COLLABORATION: 'Collaborazione digitale',
  DIGITAL_COMMUNICATION: 'Comunicazione digitale',
  DIGITAL_PROBLEM_SOLVING: 'Problem Solving digitale',
  DIGITAL_LEADERSHIP: 'Leadership digitale',
  AUTONOMY: 'Autonomia',
  PROACTIVITY: 'Proattività',
  EMPATY: 'Empatia',
  DIGITAL_TECHNOLOGY: 'Tecnologie digitali',
  INTERCULTURALITY: 'Interculturalità'
};
