import { Divider, withStyles } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import WarningIcon from '@material-ui/icons/Warning';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { change, destroy, Field, initialize, reduxForm, submit } from 'redux-form';

import * as ModalsActions from '../../../redux/actions/modal.actions';
import * as SkillsActions from '../../../redux/actions/skills.actions';
import * as UtilsActions from '../../../redux/actions/utils.actions';
import { EDULAI_BLUE, EDULAI_PURPLE, ORANGE } from '../../../styles/styleConsts';
import translations from '../../../translations/i18next';
import SubSkillRow from '../../ListComponents/RowComponents/SubSkillRow';
import MDButton from '../../MDButton/MDButton';
import MDTextInputField from '../FormsComponents/MDTextInput/MDTextInputField';
import EditSkillForm from './EditSkillForm';
import SubSkillForm from './SubSkillForm';
import { isMobileBrowser } from '../../../utils/utilsFunctions';

const validate = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = 'required';
  }
  return errors;
};

const styles = (theme) => ({
  formContainer: {
    margin: 20,
    marginTop: 20,
  },
  title: {
    margin: 0,
    marginTop: 20,
  },
  warningText: {
    fontWeight: 'normal',
    marginLeft: 10,
  },
  headerTitle: {
    marginLeft: 20,
    color: EDULAI_PURPLE,
  },
  subTitle: {
    margin: 0,
    fontWeight: '100',
    marginBottom: 20,
  },
  warningContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const theme = createMuiTheme({
  palette: {
    primary: { 500: EDULAI_BLUE },
    secondary: { main: '#CC0033' },
  },
  typography: {
    useNextVariants: true,
  },
});

class SkillForm extends React.Component {
  onNewSubSkillClicked() {
    const { dispatch } = this.props;
    dispatch(destroy('SubSkillForm'));
    dispatch(SkillsActions.setSelectSubSkill({}));
    dispatch(
      ModalsActions.showModal('SUB_SKILL_MODAL', {
        modalType: 'OPERATIONAL_VIEW',
        modalProps: {
          content: <SubSkillForm onSubmit={(subSkillData) => this.onCreateSubSkill(subSkillData)} />,
          title: translations.t('forms.newSubSkill'),
        },
      }),
    );
  }

  onEditSubSkill(subSkill) {
    const { dispatch } = this.props;
    dispatch(destroy('SubSkillForm'));
    dispatch(initialize('SubSkillForm', subSkill));
    dispatch(SkillsActions.setSelectSubSkill(subSkill));
    dispatch(
      ModalsActions.showModal('SUB_SKILL_MODAL', {
        modalType: 'OPERATIONAL_VIEW',
        modalProps: {
          content: (
            <SubSkillForm
              subSkill={subSkill}
              onSubmit={(subSkillData) => this.modifySubSkill(subSkillData)}
              onDeleteSubSkill={() => this.onDeleteSubSkillRequest(subSkill)}
            />
          ),
          title: translations.t('forms.subSkills'),
        },
      }),
    );
  }

  async onCreateSubSkill(subSkillData) {
    const { dispatch, skill } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisible(true));
      const updatedSkill = await dispatch(SkillsActions.createSubSkill(skill, subSkillData));
      dispatch(UtilsActions.setSpinnerVisible(false));
      dispatch(SkillsActions.setSelectSubSkill({}));
      dispatch(SkillsActions.setSelectedSkill(updatedSkill));
      dispatch(ModalsActions.hideModal('SUB_SKILL_MODAL'));
      dispatch(
        ModalsActions.showModal('NEW_SKILL_SUCCESS', {
          modalType: 'SUCCESS_ALERT',
          modalProps: {
            message: translations.t('forms.skillCreatedSuccess'),
          },
        }),
      );
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisible(false));
      dispatch(
        ModalsActions.showModal('NEW_SKILL_ERROR', {
          modalType: 'ERROR_ALERT',
          modalProps: {
            message: translations.t('forms.skillCreatedError'),
          },
        }),
      );
    }
  }

  onDeleteSubSkillRequest(subSkill) {
    const { dispatch } = this.props;
    dispatch(
      ModalsActions.showModal('DELETE_SUB_SKILL_MODAL', {
        modalType: 'MODAL_DIALOG',
        modalProps: {
          title: translations.t('forms.warning'),
          bodyText: translations.t('forms.subSkillDeleteWarning'),
          onConfirm: () => this.onDeleteSubSkill(subSkill),
        },
      }),
    );
  }

  async onDeleteSubSkill(subSkill) {
    const { dispatch, skill } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisible(true));
      const skillUpdated = await dispatch(SkillsActions.deleteSubSkill(skill, subSkill));
      dispatch(UtilsActions.setSpinnerVisible(false));
      dispatch(SkillsActions.setSelectedSkill(skillUpdated));
      dispatch(ModalsActions.hideModal('DELETE_SUB_SKILL_MODAL'));
      dispatch(ModalsActions.hideModal('SUB_SKILL_MODAL'));
      dispatch(
        ModalsActions.showModal('DELETE_SUB_SKILL_SUCCESS_MODAL', {
          modalType: 'SUCCESS_ALERT',
          modalProps: {
            message: translations.t('forms.subSkillDeleteSuccess'),
          },
        }),
      );
    } catch (error) {
      dispatch(ModalsActions.hideModal('DELETE_SUB_SKILL_MODAL'));
      dispatch(UtilsActions.setSpinnerVisible(false));
      const message =
        error.response && error.response.data && error.response.data.code === 5004
          ? translations.t('forms.cantDeleteReferencedSkill')
          : translations.t('forms.subSkillDeleteError');
      dispatch(
        ModalsActions.showModal('DELETE_SUB_SKILL_ERROR_MODAL', {
          modalType: 'ERROR_ALERT',
          modalProps: {
            message,
          },
        }),
      );
    }
  }

  async modifySubSkill(skillData) {
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisible(true));
      await dispatch(SkillsActions.updateSubSkill(skillData));
      dispatch(UtilsActions.setSpinnerVisible(false));
      dispatch(
        ModalsActions.showModal('SUBSKILL_UPDATE_SUCCESS_MODAL', {
          modalType: 'SUCCESS_ALERT',
          modalProps: {
            message: translations.t('forms.skillUpdatedSuccess'),
          },
        }),
      );
      dispatch(ModalsActions.hideModal('SUB_SKILL_MODAL'));
    } catch (error) {
      dispatch(
        ModalsActions.showModal('SUBSKILL_UPDATE_ERROR_MODAL', {
          modalType: 'ERROR_ALERT',
          modalProps: {
            message: translations.t('forms.skillUpdatedError'),
          },
        }),
      );
      dispatch(UtilsActions.setSpinnerVisible(false));
    }
  }

  showEditSkillModal(skill) {
    const { dispatch } = this.props;
    dispatch(destroy('EditSkillForm'));
    dispatch(initialize('EditSkillForm', skill));
    dispatch(
      ModalsActions.showModal('EDIT_SKILL_FORM', {
        modalType: 'MODAL_DIALOG',
        modalProps: {
          onConfirm: () => {
            dispatch(submit('EditSkillForm'));
          },
          confirmText: translations.t('modals.confirm'),
          content: (
            <EditSkillForm
              onSubmit={(values) => {
                dispatch(change('SkillForm', 'name', values.name));
                dispatch(ModalsActions.hideModal('EDIT_SKILL_FORM'));
              }}
            />
          ),
        },
      }),
    );
  }

  render() {
    const { classes, dispatch, skill, form, onDeleteSkill } = this.props;
    const isEditing = skill && !_.isEmpty(skill);
    return (
      <MuiThemeProvider theme={theme}>
        {!isEditing ? <h2 className={classes.headerTitle}>{translations.t('skills.systemSkills')}</h2> : null}
        <div className={classes.formContainer}>
          {isEditing ? (
            <div>
              <div style={{ display: 'flex' }}>
                <h2 style={{ color: EDULAI_PURPLE }}>{form.values.name}</h2>
                <EditIcon
                  style={{ color: '#888', margin: 'auto', marginRight: 0 }}
                  onClick={() => this.showEditSkillModal(skill)}
                />
                <DeleteIcon
                  style={{
                    color: '#888',
                    margin: 'auto',
                    marginRight: 0,
                    marginLeft: 10,
                  }}
                  onClick={() => onDeleteSkill(skill)}
                />
              </div>
              <Divider style={{ height: 2 }} />
            </div>
          ) : (
            <Field
              name="name"
              component={MDTextInputField}
              containerstyle={{ marginBottom: 10 }}
              label={translations.t('forms.skillName')}
              variant="outlined"
              required
            />
          )}
          {!isEditing && (
            <div className={classes.warningContainer}>
              <WarningIcon style={{ color: EDULAI_BLUE }} />
              <h4 className={classes.warningText}>{translations.t('forms.newSkillWarning')}</h4>
            </div>
          )}
          <MDButton
            title={translations.t('forms.save')}
            backgroundColor={EDULAI_BLUE}
            containerstyle={{ marginBottom: 20, display: 'flex', justifyContent: 'center' }}
            onClick={() => dispatch(submit('SkillForm'))}
            buttonStyle={{ width: isMobileBrowser() ? '100%' : '40%' }}
          />
          <h4 style={{ marginBottom: 5, color: EDULAI_PURPLE }}>{translations.t('skills.systemSubSkills')}</h4>
          <h4 className={classes.subTitle}>{translations.t('skills.systemSubSkillsDescription')}</h4>
          <MDButton
            title={translations.t('forms.newSubSkill')}
            backgroundColor={ORANGE}
            containerstyle={{ marginBottom: 20, display: 'flex', justifyContent: 'center' }}
            onClick={() => this.onNewSubSkillClicked()}
            buttonStyle={{ width: isMobileBrowser() ? '100%' : '40%' }}
          />
          {_.map(skill.subSkills, (subSkill) => (
            <SubSkillRow subSkill={subSkill} onClick={() => this.onEditSubSkill(subSkill)} />
          ))}
        </div>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  form: state.form.SkillForm,
  skill: state.skills.selectedSkill,
});

export default _.flow([
  connect(mapStateToProps),
  reduxForm({
    form: 'SkillForm',
    validate,
    destroyOnUnmount: false,
  }),
  withStyles(styles),
])(SkillForm);
