import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import logoImage from '../../assets/logo_white.png';
import additionalImage from '../../assets/report_soft_skill.png';
import reportFrontImage from '../../assets/report-front.png';
import translations from '../../translations/i18next';
import _, { startsWith } from 'lodash';

const captureElement = async (element) => {
  return html2canvas(element, {
    scale: 2,
    windowWidth: 1280,
    windowHeight: 1280
  }).then((canvas) => canvas.toDataURL('image/png'));
};

const captureChart = async (element) => {
  return html2canvas(element, {
    scale: 2,
    windowWidth: 1600,
    windowHeight: 1600
  }).then((canvas) => canvas.toDataURL('image/png'));
};

// TODO: refactor
export async function generateAndDownloadPdf(data, firstPageId, skillsId) {
  const pdf = new jsPDF('p', 'mm', 'a4');
  await drawIntestationPage(pdf, data);
  pdf.addPage();
  await addFirstPage(pdf, firstPageId);
  pdf.addPage();
  await addSkillsPointPage(pdf, skillsId);

  // Save the PDF
  pdf.save(`Edulai Report ${data.name} ${data.date}.pdf`);
}

const drawIntestationPage = async (pdf, userData) => {
  const pdfWidth = pdf.internal.pageSize.getWidth();
  const pdfHeight = pdf.internal.pageSize.getHeight();

  // front background
  const frontReportImage = new Image();
  frontReportImage.src = reportFrontImage;
  await frontReportImage.decode();
  const frontWidth = pdfWidth;
  const frontHeight = pdfHeight;
  pdf.addImage(frontReportImage, 'PNG', 0, 0, frontWidth, frontHeight, undefined, 'FAST');

  // edulai skillchecker logo
  const edulaiLogo = new Image();
  edulaiLogo.src = logoImage;
  await edulaiLogo.decode();
  const logoWidth = 50;
  const logoHeight = 25;
  pdf.addImage(edulaiLogo, 'PNG', 20, 20, logoWidth, logoHeight, undefined, 'FAST');

  // skills logo
  const skillsLogo = new Image();
  skillsLogo.src = additionalImage;
  await skillsLogo.decode();
  const skillsLogoWidth = 40;
  const skillsLogoHeight = 35;
  const skillsLogoX = 20;
  const skillsLogoY = pdfHeight / 2 - 60;
  pdf.addImage(skillsLogo, 'PNG', skillsLogoX, skillsLogoY, skillsLogoWidth, skillsLogoHeight, undefined, 'FAST');

  // title
  pdf.setTextColor(255, 165, 0);
  pdf.setFontSize(30);
  pdf.setFont('helvetica', 'bold');
  const reportText = 'REPORT EDULAI';
  const reportTextX = 20;
  const reportTextY = pdfHeight / 2 - 10;
  pdf.text(reportText, reportTextX, reportTextY);

  // footer
  pdf.setTextColor(255, 255, 255);
  pdf.setFontSize(16);
  pdf.setFont('helvetica', 'bold');
  pdf.text(userData.name, 20, pdfHeight - 60);
  pdf.setTextColor(255, 255, 255);
  pdf.setFontSize(12);
  pdf.setFont('helvetica', 'normal');
  pdf.text(userData.date, 20, pdfHeight - 50);
  pdf.text(translations.t('analytics.private'), 20, pdfHeight - 45);
};

const addFirstPage = async (pdf, chartIds) => {
  const pdfWidth = pdf.internal.pageSize.getWidth();
  const pdfHeight = pdf.internal.pageSize.getHeight();
  const margin = 10;
  let yOffset = margin + 10;
  const contentWidth = pdfWidth - margin * 2;
  const contentHeight = pdfHeight - margin * 2;
  const imageScale = 1;

  for (let id of chartIds) {
    const element = document.getElementById(id);
    const imgData = startsWith(id, 'chart') ? await captureChart(element) : await captureElement(element);

    const imgProps = pdf.getImageProperties(imgData);
    const imgWidth = imgProps.width * imageScale * (contentWidth / imgProps.width);
    const imgHeight = imgProps.height * imageScale * (contentWidth / imgProps.width);
    const xPosition = (pdfWidth - imgWidth) / 2;

    if (yOffset + imgHeight > contentHeight) {
      pdf.addPage();
      yOffset = margin;
    }

    pdf.addImage(imgData, 'PNG', xPosition, yOffset, imgWidth, imgHeight, undefined, 'FAST');
    yOffset += imgHeight + 10;
  }
};

const addSkillsPointPage = async (pdf, chartIds) => {
  const pdfWidth = pdf.internal.pageSize.getWidth();
  const pdfHeight = pdf.internal.pageSize.getHeight();
  const margin = 10;
  let yOffset = margin;
  const contentWidth = pdfWidth - margin * 2;
  const contentHeight = pdfHeight - margin * 2;
  const imageScale = 1;

  for (let id of chartIds) {
    const element = document.getElementById(id);
    const imgData = await captureElement(element);

    const imgProps = pdf.getImageProperties(imgData);
    const imgWidth = imgProps.width * imageScale * (contentWidth / imgProps.width);
    const imgHeight = imgProps.height * imageScale * (contentWidth / imgProps.width);
    const xPosition = (pdfWidth - imgWidth) / 2;

    if (yOffset + imgHeight > contentHeight) {
      pdf.addPage();
      yOffset = margin;
    }

    pdf.addImage(imgData, 'PNG', xPosition, yOffset, imgWidth, imgHeight, undefined, 'FAST');
    yOffset += imgHeight + 10;
  }
};
