import { map, filter, maxBy, orderBy, compact } from 'lodash';
import React from 'react';
import { EDULAI_BLUE, EDULAI_ORANGE, EDULAI_PURPLE } from '../../styles/styleConsts';
import strenghtIcon from '../../assets/strengths.svg';
import weaknessIcon from '../../assets/weaknesses.svg';
import translations from '../../translations/i18next';
import { joinWithCommas } from './SubSkillDescription';
import { connect } from 'react-redux';
import { isMobileBrowser } from '../../utils/utilsFunctions';
import { Translations } from '../../translations/Translations';

const SkillDescription = ({ skillsAnalytics, selfAssessmentData, lang, style, testId }) => {
  const selfAssessmentDataValue = orderBy(selfAssessmentData, (skill) => skill.skillId);

  const scoreDifference = compact(
    map(skillsAnalytics, (skill, index) => {
      if (!skill.value) {
        return null;
      }
      return {
        name: skill.name,
        value: Number(selfAssessmentDataValue[index].score) - Number(skill.value)
      };
    })
  );

  const diff1 = filter(scoreDifference, (diff) => diff.value <= -1);
  const diff2 = filter(scoreDifference, (diff) => diff.value >= 1);
  const diff3 = filter(scoreDifference, (diff) => Math.abs(diff.value) < 0.9);

  const countSkillMap = [
    { key: 'skillReport1', count: diff1.length, skills: map(diff1, 'name') },
    { key: 'skillReport2', count: diff2.length, skills: map(diff2, 'name') },
    { key: 'skillReport3', count: diff3.length, skills: map(diff3, 'name') }
  ];

  const max = maxBy(countSkillMap, 'count');

  const highScores = filter(skillsAnalytics, (skill) => Number(skill.value) >= 4);
  const lowScores = filter(skillsAnalytics, (skill) => Number(skill.value) <= 2 && Number(skill.value) > 0);
  const threeScoresList = filter(skillsAnalytics, (skill) => Number(skill.value) === 3);

  return (
    <div style={{ ...style }} id={testId}>
      {map(countSkillMap, (skill) => {
        if (skill.count && skill.count === max.count) {
          return (
            <p style={{ width: '100%', color: EDULAI_PURPLE, fontSize: 16 }}>
              <Translations>
                {translations.t(`skillsReport.${skill.key}`, {
                  skills: joinWithCommas(skill.skills)
                })}
              </Translations>
            </p>
          );
        } else {
          return null;
        }
      })}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center'
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            borderRadius: 8,
            backgroundColor: 'rgba(245, 125, 36, 0.12)',
            padding: 20,
            margin: 20,
            marginLeft: 0,
            width: isMobileBrowser() ? '100%' : '50%'
          }}
        >
          <img src={strenghtIcon} style={{ width: 60, alignSelf: 'center' }} alt="strenght" />
          <h2 style={{ color: EDULAI_ORANGE, textAlign: 'center' }}>{translations.t(`skillsReport.strenghts`)}</h2>
          {highScores.length ? (
            <p style={{ fontSize: 16, color: EDULAI_PURPLE }}>
              <Translations>
                {translations.t(`skillsReport.skillStrenghtMessage`, {
                  skills: joinWithCommas(map(highScores, 'name'), lang)
                })}
              </Translations>
            </p>
          ) : (
            <p style={{ fontSize: 16, color: EDULAI_PURPLE }}>{translations.t(`skillsReport.noStrenghts`)}</p>
          )}
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            borderRadius: 8,
            backgroundColor: 'rgba(77, 102, 185, 0.08)',
            padding: 20,
            margin: 20,
            marginRight: 0,
            width: isMobileBrowser() ? '100%' : '50%'
          }}
        >
          <img src={weaknessIcon} style={{ width: 60, textAlign: 'center', alignSelf: 'center' }} alt="weakness" />
          <h2 style={{ color: EDULAI_BLUE, textAlign: 'center' }}>{translations.t(`skillsReport.weaknesses`)}</h2>
          {lowScores.length || threeScoresList.length ? (
            <p style={{ color: EDULAI_PURPLE, fontSize: 16 }}>
              <Translations>
                {lowScores.length !== 0
                  ? translations.t(`skillsReport.skillWeaknessLowMessage`, {
                      skills: joinWithCommas(map(lowScores, 'name'), lang)
                    })
                  : null}
              </Translations>
              <Translations>
                {threeScoresList.length !== 0
                  ? translations.t(`skillsReport.skillWeaknessMediumMessage`, {
                      skills: joinWithCommas(map(threeScoresList, 'name'), lang)
                    })
                  : null}
              </Translations>
            </p>
          ) : (
            <p style={{ color: EDULAI_PURPLE, fontSize: 16 }}>{translations.t(`skillsReport.noWeaknesses`)}</p>
          )}
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    lang: state.utils.selectedLanguage
  };
}

export default connect(mapStateToProps)(SkillDescription);
