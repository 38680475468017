import { withStyles, createMuiTheme, MuiThemeProvider, IconButton } from '@material-ui/core';
import _ from 'lodash';
import Chip from '@material-ui/core/Chip';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import React from 'react';
import { EDULAI_BLUE, EDULAI_PURPLE } from '../../../../styles/styleConsts';
import { Check } from '@material-ui/icons';

const styles = () => ({
  infoButton: {
    marginLeft: 'auto',
    fontSize: 32,
    display: 'flex',
    color: 'rgba(0,0,0,0.53)',
  },
  rowContent: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: 18,
  },
  skillTag: {
    marginRight: 10,
    paddingLeft: 10,
    paddingRight: 10,
    marginTop: 15,
  },
  title: {
    color: '#3f3f3f',
    fontWeight: 'bold',
    marginBottom: 0,
  },
});

const theme = createMuiTheme({
  palette: {
    primary: { 500: EDULAI_PURPLE },
    seconday: EDULAI_PURPLE,
  },
  typography: {
    useNextVariants: true,
  },
});

const SelectableRow = ({
  title,
  skills,
  type,
  onClick,
  isChecked,
  hasError,
  customOptionElement,
  classes,
  onInfoClick,
}) => (
  <MuiThemeProvider theme={theme}>
    <Card
      className="selectable-row"
      style={{
        marginBottom: 15,
        backgroundColor: hasError ? 'rgb(255, 109, 123)' : isChecked ? '#ECEFF4' : 'white',
        display: 'flex',
        alignItems: 'center',
        borderRadius: 8,
        boxShadow: isChecked ? 'none' : '0px 3px 3px #D1D5DB29',
        border: isChecked ? '1px solid #5048E6' : 'none',
      }}
    >
      <CardActionArea style={{ padding: 10, width: '100%' }} onClick={() => onClick()}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span className={classes.rowContent} onClick={() => onClick()} aria-hidden="true">
            {isChecked ? (
              <div
                style={{
                  width: '18px',
                  height: '18px',
                  backgroundColor: EDULAI_BLUE,
                  border: '1px solid #6B7280',
                  borderRadius: '2px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  margin: 20,
                }}
              >
                <Check style={{ color: 'white', fontSize: 16 }} />
              </div>
            ) : (
              <div
                style={{
                  width: '18px',
                  height: '18px',
                  backgroundColor: '#fff',
                  border: '1px solid #6B7280',
                  borderRadius: '2px',
                  margin: 20,
                }}
              />
            )}
          </span>
          <div className="resourceInfoRow-titleType">
            {customOptionElement ? (
              <div>{customOptionElement}</div>
            ) : (
              <div>
                <h2 className={classes.title}>{title}</h2>
                {skills &&
                  _.map(skills, (skill) => (
                    <Chip
                      key={skill.name}
                      className={classes.skillTag}
                      color="#F57D24"
                      label={skill.name}
                      style={{ backgroundColor: '#F57D24', color: 'white' }}
                    />
                  ))}
                <p style={{ color: '#3f3f3f' }}>{type}</p>
              </div>
            )}
          </div>
          {onInfoClick ? (
            <IconButton
              className={classes.infoButton}
              onClick={(event) => {
                event.stopPropagation();
                onInfoClick();
              }}
            >
              <InfoOutlinedIcon fontSize="inherit" style={{ color: EDULAI_BLUE }} />
            </IconButton>
          ) : null}
        </div>
      </CardActionArea>
    </Card>
  </MuiThemeProvider>
);

export default withStyles(styles)(SelectableRow);
